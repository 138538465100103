import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { smoothlyMenu } from "../layouts/Helpers";
import { Link } from "react-router-dom";
import appLabel from "../../config/appLabel";

const TopHeader = (props) => {
  const { isSignedIn, userData } = props;
  const {
    userRoleName,
    profileFirstName,
    profileLastName,
    companyName,
    companyTypeName,
  } = userData;

  const toggleNavigation = (e) => {
    e.preventDefault();
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  };
  return (
    <div className="row border-bottom white-bg">
      <nav
        className="navbar navbar-static-top white-bg"
        role="navigation"
        style={{ marginBottom: 0, boxShadow: "0 6px 6px -6px gray" }}
      >
        <div className="navbar-header">
          <a
            className="navbar-minimalize minimalize-styl-2 btn btn-primary btn-rounded"
            onClick={(e) => toggleNavigation(e)}
            href="#"
          >
            <i className="fa fa-bars"></i>{" "}
          </a>
        </div>
        <div
          className="navbar-header"
          style={{ position: "relative", paddingLeft: "18px" }}
        >
        </div>
        <ul className="nav navbar-top-links navbar-right">
          <li className="dropdown">
            <a className="dropdown-toggle count-info" data-toggle="dropdown">
              <i className="fa fa-user text-navy" />
              &nbsp;{profileFirstName} {profileLastName}&nbsp;
              <b className="caret" />
            </a>
            <ul className="dropdown-menu dropdown-messages">
              <li>
                <div className="dropdown-messages-box">
                  <a ui-sref="home" className="pull-left">
                    <i className="fa fa-building-o fa-2x text-navy" />
                  </a>
                  <div className="media-body">
                    <strong>{companyName}</strong>
                    <br />
                    <small className="text-muted">{companyTypeName}</small>
                    {/* <br /> */}
                    <span className="label label-primary pull-right">
                      {userRoleName}
                    </span>
                  </div>
                </div>
              </li>
              <li className="divider" />

              <li>
                <Link to="/change-password">
                  <i className="fa fa-edit text-navy" />
                  &nbsp;{appLabel.changePwd}
                </Link>
              </li>
              <li>
                <Link to="/lockscreen">
                  <i className="fa fa-lock text-navy" /> {appLabel.lockscreen}
                </Link>
              </li>
              <li>
                <Link to="/sign-in">
                  <i className="fa fa-sign-out text-navy" /> {appLabel.logout}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
