import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { cnt } from "../../services/constant.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import RecordStatus from "../ui-setup/RecordStatus";
import {
  Tooltips,
  ColBlockFac,
  NoDataFac,
  BackBtnFac,
  OutlineFldErr,
} from "../layouts/Facility";
import GlobalDetailScreen from "../layouts/GlobalDetailScreen";
import appStatic from "../../config/appStaticData";

export const DetailAccount = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { location, userData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session: sessionId, userRoleId } = userData;
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState({});
  const [inputFldData, setInputFldData] = useState([]);
  const [isComment, setIsComment] = useState(true);
  const [showHide, setShowHide] = useState({
    isReject: false,
    isMaker: false,
    isDisabled: false,
    isChecked: false,
    isApproved: false,
    isRejected: false,
    isForOfficialUsed: false,
    isCompanyClientType: false,
  });
  const { HandleFlgStatus} = appStatic;


  useEffect(() => {
    getDetailById(ggParam);
  }, []);

  const getDetailById = (params) => {

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      userId: params.userId,
      sessionId,
    };

    BOM.FetchReqAction(body, api.GetUserDetails, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
      } else {
        BOM.LoadTimeout();
        let userDetail = res.data;
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: userDetail,
        });
        setValue("reason", userDetail.comments);

        // check if company or individual
        const isCompanyType = userDetail?.clientType?.toLowerCase() === "company"  ? true : false
        setShowHide({
          ...showHide,
          isCompanyClientType:isCompanyType,
        });

        setInputFldData([
          {
            name: appLabel.profileId,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.userId,
            },
          },
          {
            name: appLabel.username,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.username,
            },
          },
          {
            name: appLabel.firstName,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.userFirstname,
            },
          },
          {
            name: appLabel.lastName,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.userLastName,
            },
          },
          {
            name: appLabel.status,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: HandleFlgStatus(userDetail.flgStatus),
            },
          },
         
          {
            name: isCompanyType ? appLabel.companyAccountName : appLabel.accountName,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.accountName,
            },
          },
          {
            name: isCompanyType ? appLabel.companyAccountNo : appLabel.accountNumber,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.accountNumber,
            },
          },
          {
            name: isCompanyType ? appLabel.companyTin : appLabel.tin,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.tin,
            },
          },
          {
            name: appLabel.phoneNumber,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.userPhoneNumber,
            },
          },
          {
            name: appLabel.idType,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.idNumberTypeName,
            },
          },
          
          {
            name: `${userDetail.idNumberTypeName} Number`,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.idNumber,
            },
          },
          {
            name: appLabel.currency,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.accountCurrency,
            },
          },
          {
            name: appLabel.clientType,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.clientType,
            },
          },
        ]);

        switch (userRoleId) {
          case "RL0000000000010": // maker
            setShowHide({
              ...showHide,
              isMaker: true,
            });
            BOM.IsApproveOrReject(
              userDetail.flgStatus,
              menuData,
              "Manage Account"
            );
            break;
          case "RL0000000000011": // checker
            
            BOM.IsApproveOrReject(
              userDetail.flgStatus,
              menuData,
              "Manage Account"
            );

            break;
            default:
              BOM.IsApproveOrReject(
                'none',
                menuData,
                "Manage Account"
              );
              break;
        }

        if (userDetail.comments) {
          setShowHide({ ...showHide, isReject: true });
        }

        if (userDetail.flgStatus.toLowerCase() === 'r') {
          setShowHide({ ...showHide, isRejected: true, isDisabled:true });
        }
      }
    });
  };
  const OnError = (errors) => {
    setShowHide({ ...showHide, isReject: true });
    BOM.AlertMsg(cnt.WARNING, errors.reason.message);
  };
  const onSelect = (event, key) => {
    let check = event.target.checked;

    // console.log(check);
    switch (key.toLowerCase()) {
      case "checker-verification":
        setShowHide({ ...showHide, isChecked: check });
        break;

      default:
        break;
    }
  };
  const onApprove = () => {
    let approveStatus = "M";
    let actionMsg = "Approve"

    switch (userRoleId) {
      case "RL0000000000010": // maker
      if (!showHide.isChecked) {
        BOM.AlertMsg(cnt.WARNING, appLabel.checkboxMsg);
        setShowHide({
          ...showHide,
          isForOfficialUsed: true,
          isReject: false,
          isMaker: true,
        });
        return;
      }
        approveStatus = "C";
        break;
      case "RL0000000000011": // checker
        approveStatus = "A";
        actionMsg = "Authorize"
        break;
    }

    let body = {
      profileId,
      userId: ggParam.userId,
      status: approveStatus,
      sessionId,
    };

    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectCustomer,
      "dashboard",
      `${ggParam.userFirstname} ${ggParam.userLastname}`,
     `${actionMsg} `
    );
  };
  const onReject = (formValues) => {
    if (!formValues.reason) {
      setShowHide({
        ...showHide,
        isForOfficialUsed: true,
        isReject: true,
        isMaker: false,
        isChecked:false
      });

      BOM.AlertMsg(cnt.WARNING, BOM.CheckReqFld(appLabel.rejectReason));
      return;
    }
    formValues.status = "R";

    let body = {
      profileId,
      userId: ggParam.userId,
      emailAddress: ggParam.username,
      phoneNumber:ggParam.phoneNumber,
      ...formValues,
      sessionId,
    };

    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectCustomer,
      "dashboard",
      `${ggParam.userFirstname} ${ggParam.userLastname}`,
      "Reject "
    );
  };

  return (
    <Main
      {...props}
      onApprove={onApprove}
      onReject={handleSubmit(onReject, OnError)}
    >
      <form autoComplete="off">
        <ContentTop pageTitle={appLabel.accountDetail} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-content content-card-v2">
                  <div className="row">
                    <ColBlockFac>
                      <BackBtnFac />
                    </ColBlockFac>
                    <div className="col-sm-1">
                      
                      <div className="text-center">
                        <img
                          src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                          className="avatar img-circle img-thumbnail"
                          alt="avatar"
                        />
                      </div>
                      <br />
                    </div>
                    
                    <div className="col-sm-11">

                      <GlobalDetailScreen
                        arrayData={inputFldData}
                        topTitleParam={appLabel.customerInfo}
                        sectionParam={appLabel.secGeneral}
                      />
                      <GlobalDetailScreen
                        arrayData={inputFldData}
                        topTitleParam={appLabel.accountInformation}
                        sectionParam={appLabel.secBank}
                      />
                      {(showHide.isForOfficialUsed || showHide.isRejected) && (
                        <ColBlockFac topTitle={"FOR OFFICIAL USE ONLY"}>
                          {(showHide.isReject || showHide.isRejected) && (
                            <div className="row form-group col-md-12">
                              <div className="col-md-2 text-left">
                                <label className="al-subtitle">
                                  {"Reason/Comment"}&nbsp;
                                  <Tooltips
                                    title={
                                      "When rejecting a user, a reason or comment is required."
                                    }
                                  />
                                </label>
                              </div>
                              <div className="col-md-8">
                                <textarea
                                disabled={showHide.isDisabled}
                                  style={{
                                    width: "100%",
                                    height: 100,
                                    padding: 5,
                                  }}
                                  className={`form-control ${OutlineFldErr(
                                    errors.reason
                                  )}`}
                                  placeholder={
                                    "Give a Reason / Comment on why you are doing this action."
                                  }
                                  {...register("reason", {
                                    required: isComment
                                      ? BOM.CheckReqFld(appLabel.rejectReason)
                                      : false,
                                    minLength: {
                                      value: 1,
                                      message:
                                        "Minimum length is 1 character",
                                    },
                                    maxLength: {
                                      value: 200,
                                      message:
                                        "Maximum length is 200 characters",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.reason?.message}
                                </span>
                              </div>
                            </div>
                          )}
                          {showHide.isMaker && (
                            <div className="global_wrapper">
                              <div className="switcher_wrapper">
                                <input
                                  id="switcher"
                                  type="checkbox"
                                  name="switcher"
                                  disabled={showHide.isDisabled}
                                  checked={showHide.isChecked}
                                  onChange={(e) =>
                                    onSelect(
                                      e,
                                      "checker-verification"
                                    )
                                  }
                                />
                                <label
                                  htmlFor="switcher"
                                  className="switcher_checkbox"
                                >
                                  <span>{appLabel.confirmApprovalMsg}</span>
                                  <span className="checker">
                                    <span className="checker_short-line" />
                                    <span className="checker_long-line" />
                                  </span>
                                </label>
                              </div>
                            </div>
                          )}
                        </ColBlockFac>
                      )}
                    </div>
                  </div>
                  <RecordStatus {...recordData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  recordData: state.recordStatus,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailAccount);
