import React, { useEffect, useState } from "react";
import Main from "../../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import {
  TableFacility,
  AmntFormatFac,
  HandleSkeletonTbleFac,
  ColBlockFac,
} from "../../layouts/Facility";
import history from "../../../history";

const ManageUser = (props) => {
  const { userData, menuData, location } = props;
  const { profileId, session, companyId } = userData;
  const { tableData } = appStatic;

  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [usersData, setUsersData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: appLabel.authorizedListing,
      color: "primary",
      icon: "check-circle",
      criteria: "A",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    // {
    //   title: appLabel.unauthorized,
    //   otherTitle: appLabel.unauthorizedListing,
    //   color: "warning",
    //   icon: "exclamation-triangle",
    //   criteria: "U",
    //   callback: function (params) {
    //     getListByCriteria(params);
    //   },
    // },
    {
      title: appLabel.disabled,
      otherTitle: appLabel.disabledListing,
      color: "danger",
      icon: "close",
      criteria: "D",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    // console.log(props);
    getListByCriteria(tiles[0]);
  }, []);

  function getListByCriteria(params) {
    // console.log(params);
    setUsersData([]);
    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    let body = {
      profileId,
      criteria: params.criteria,
      sessionId: session,
    };

    setIsLoading(true);

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetUser, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        setIsLoading(false);
      } else {
        setUsersData(res.data.adminUsers);
        BOM.DatatableUsage();
        setIsLoading(false);
        // BOM.ReloadComponent()
      }
    });
  }

  const onSelectItem = (obj) => {

    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }

    if (path) {
      if (RAL.editAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_EDIT_BTN,
        });
      }
    }

    setSelectedItem(obj);
  };

  const onCreate = () => {
    history.push("/new-user");
  };
  const onViewDetail = () => {
    const obj = selectedItem;
     history.push("/user-detail", {
      ggParam: obj,
    });
  };

  const onEdit = () => {
    const obj = selectedItem;
     history.push("/edit-user", {
      ggParam: obj,
    });
  };
  /** RENDER JXS DATA */
  return (
    <Main {...props} onViewDetail={onViewDetail} onCreate={onCreate} onEdit={onEdit}>
      <ContentTop pageTitle={appLabel.manageUser} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-content content-card-v2">
                <ColBlockFac>
                  <div className="ibox-tools">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <span className={`btn __btn-primary btn-rounded btn-sm`}>
                        <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight}{" "}
                        &nbsp;
                        <i className="fa fa-chevron-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      {tiles.map((tile, tileIndex) => {
                        if (tile.title !== titleRight) {
                          return (
                            <li key={tileIndex}>
                              <a onClick={() => tile.callback(tile)}>
                                {" "}
                                {tile.title}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </ColBlockFac>

                <div className="col-lg-12 m-t">
                  <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                    {usersData.length === 0 ? (
                      <div className="alert alert-warning text-center">
                        <a className="alert-link">
                          {appLabel.noDataInTableMsg}
                        </a>
                      </div>
                    ) : (
                      <TableFacility thData={tableData.ListUser}>
                        {usersData.map((item, index) => (
                          <tr className="gradeX" key={index}>
                            <td>
                              <label className="myradio-button">
                                <input
                                  type="radio"
                                  name="radio"
                                  onClick={() => onSelectItem(item)}
                                />
                                <span className="label-visible">
                                  <span className="myfake-radiobutton"></span>
                                </span>
                              </label>
                            </td>
                            <td>{item.userId}</td>
                            <td>{item.userFirstname}</td>
                            <td>{item.userLastname}</td>
                            <td>{item.username}</td>
                            <td className="center">{item.roleName}</td>
                          </tr>
                        ))}
                      </TableFacility>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
