import React, { useEffect, useState } from "react";
import Main from "../../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import { HandleSkeletonTbleFac, ColBlockFac, TableFacility } from "../../layouts/Facility";
import history from "../../../history";

const ManageRole = (props) => {
  const { userData, menuData, location } = props;
  const {profileId, session} = userData;
  const globalVar = window.globalData;
  const { tableData } = appStatic;

  const dispatch = useDispatch();
  const [roleData, setRoleData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [tiles, setTiles] = useState([
    {
      title:  appLabel.authorized,
      otherTitle: appLabel.authorizedListing,
      color: "primary",
      icon: "tag",
      criteria: "A",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle:appLabel.unauthorizedListing,
      color: "warning",
      icon: "exclamation-triangle",
      criteria: "U",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title:  appLabel.disabled,
      otherTitle: appLabel.disabledListing,
      color: "danger",
      icon: "close",
      criteria: "R",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    getListByCriteria(tiles[0])
  }, []);

  function getListByCriteria(params) {
    // console.log(params);
    setRoleData([]);
    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    let body = {
      profileId,
      criteria: params.criteria,
      roleId: "",
      sessionId:session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetRole, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        setIsLoading(false);
      } else {
        // return
        setRoleData(res.data);
        BOM.DatatableUsage();
        setIsLoading(false)
      }
    });
  }


  const onSelectItem = (obj) => {
    

    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find((item) => item.URL === location.pathname);
    if (path) {
      // console.log(path);
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN
        });
      }
    }
    
    setSelectedItem(obj);

  };

  const onViewDetail = () => {
    const obj = selectedItem;

    if (Object.keys(obj).length === 0) return BOM.AlertMsg(cnt.WARNING, "Please select an item to open.");

    history.push("/role-detail", {
      ggParam: obj,
    });
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props} onViewDetail={onViewDetail}>
      <ContentTop pageTitle={appLabel.manageRole} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <form
            className="m-t"
            autoComplete="off"
          >
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-content content-card-v2">
                <ColBlockFac>
                  <div className="ibox-tools">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <span className={`btn __btn-primary btn-rounded btn-sm`}>
                        <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight}{" "}
                        &nbsp;
                        <i className="fa fa-chevron-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      {tiles.map((tile, tileIndex) => {

                        if (tile.title !== titleRight) {
                          return (
                            <li key={tileIndex}>
                              <a onClick={() => tile.callback(tile)}>
                                {" "}
                                {tile.title}
                              </a>
                            </li>
                          );
                        }
                        
                      })}
                    </ul>
                  </div>
                  </ColBlockFac>
                  <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                    {roleData.length === 0 ? (
                      <div className="alert alert-warning text-center">
                        <a className="alert-link">
                          {appLabel.noDataInTableMsg}
                        </a>
                      </div>
                    ) : (
                      <TableFacility thData={tableData.ListRole}>
                        {roleData.map((item, index) => (
                          <tr className="gradeX" key={index}>
                            <td>
                              <label className="myradio-button">
                                <input
                                  type="radio"
                                  name="radio"
                                  onClick={() => onSelectItem(item)}
                                />
                                <span className="label-visible">
                                  <span className="myfake-radiobutton"></span>
                                </span>
                              </label>
                            </td>
                            <td>{item.roleId}</td>
                            <td>{item.roleName}</td>
                            <td>{item.roleDescription}</td>
                          </tr>
                        ))}
                      </TableFacility>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageRole);
