import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NumberFormat from "react-number-format";
import appLabel from "../../config/appLabel";
import { Button, Modal } from "react-bootstrap";
import Bg from "../../assets/img/logoLogin/bg.jpg";
import { Link } from "react-router-dom";
import history from "../../history";
import { BOM } from "../../services/defined.action";
export const AmountFacility = (props) => {
  const {
    setIsRequiredField,
    setAmount,
    isRequiredField,
    callbackFunc,
    clearInputClass,
  } = props;

  // useEffect(()=>{
  //   $("select, input").addClass("input-radius")
  // },[])
  return (
    <>
      <NumberFormat
        thousandSeparator={true}
        className={`form-control  input-radius ${clearInputClass}`}
        placeholder={0}
        onValueChange={(values, sourceInfo) => {
          // setAmount(values.floatValue);
          if (values.floatValue >= 0) {
            setIsRequiredField(false);
            setAmount(values.floatValue);
          } else {
            setIsRequiredField(true);
          }
        }}
        onChange={callbackFunc}
      />
      {/* <span className="asterisks">
        {isRequiredField ? "Field is required." : ""}
      </span> */}
    </>
  );
};
export const TooltipsFAke = () => {
  //this will remove later it linked with orderDetail
  return <></>;
};
export const Tooltips = (props) => {
  const { title } = props;
  // return (
  //   <>
  //     <i
  //       className="fa fa-info-circle cursor-pointer text-info"
  //       data-tip={title}
  //       data-type="info"
  //     />
  //     &nbsp;
  //     <ReactTooltip />
  //   </>
  // );
  return (
    <>
      <i
        className="fa fa-info-circle cursor-pointer text-default"
        title={title}
      />
    </>
  );
};

export const ButtonRightFacility = (props) => {
  const { btnFuction, btnClass, btnIcon, title } = props;

  return (
    <>
      <div className="row text-center" style={{ marginBottom: "20px" }}>
        <a
          className={`btn ${btnClass} btn-circle btn-lg btn-outline`}
          title={title}
          onClick={() => btnFuction()}
        >
          <i className={`fa ${btnIcon}`}></i>
        </a>
      </div>
    </>
  );
};

export const BtnRightTxtIconFac = (props) => {
  const { btnFuction, btnClass, btnIcon, title, btnName, isDisabled } = props;

  return (
    <button disabled={isDisabled} className={btnClass} title={title} onClick={btnFuction}>
      <i className={`fa fa-${btnIcon}`} />
      {btnName}
    </button>
  );
};

export const BtnSubmitRightFac = (props) => {
  const { btnFunction, btnClass, btnIcon, title } = props;

  return (
    <>
      <div className="row text-center" style={{ marginBottom: "20px" }}>
        <a
          className={`btn ${btnClass} btn-circle btn-lg btn-outline`}
          title={title}
          onClick={() => btnFunction()}
        >
          <i className={`fa ${btnIcon}`}></i>
        </a>
      </div>

      {/* <div className="row text-center" style={{ marginBottom: "20px" }}>
        <button
          type="button"
          className={`btn ${btnClass} btn-circle btn-lg btn-outline`}
          title={title}
          onClick={() => btnFunction()}
        >
          <i className={`fa ${btnIcon}`}></i>
        </button>
      </div> */}
    </>
  );
};

export const InputTextFacility = (props) => {
  const { inputLabel, inputValue, toolTipLabel } = props;
  useEffect(() => {
    $("select, input").addClass("input-radius");
  }, []);
  return (
    <div className="row form-group col-md-6">
      <div className="col-md-4 text-left">
        <label className="al-subtitle">
          {inputLabel}&nbsp;
          {toolTipLabel && <Tooltips title={toolTipLabel} />}
        </label>
      </div>
      <div className="col-md-7">
        <input
          type="text"
          className="form-control  input-radius"
          defaultValue={inputValue}
          disabled
        />
      </div>
    </div>
  );
};
export const InputTextFac = (props) => {
  const { inputLabel, inputValue, toolTipLabel, ggHtmlAttributes } = props;
  useEffect(() => {}, []);
  return (
    <div className="row form-group col-md-6">
      <div className="col-md-4 text-left">
        <label className="al-subtitle">
          {inputLabel}&nbsp;
          {toolTipLabel && <Tooltips title={toolTipLabel} />}
        </label>
      </div>
      <div className="col-md-7">
        <input {...ggHtmlAttributes} />
      </div>
    </div>
  );
};
export const TextAreaFac = (props) => {
  const { inputLabel, inputValue } = props;
  useEffect(() => {
    $("select, input").addClass("input-radius");
  }, []);
  return (
    <div className="row form-group col-md-8">
      <div className="col-md-3 text-left">
        <label className="al-subtitle">{inputLabel}&nbsp;</label>
      </div>
      <div className="col-md-9">
        <textarea
          rows={10}
          cols={20}
          className="form-control  input-radius"
          defaultValue={inputValue}
          disabled
        ></textarea>
      </div>
    </div>
  );
};

export const InputTextFacilityPENDING = (props) => {
  const { inputLabel, inputValue, toolTipLabel } = props;
  useEffect(() => {
    $("select, input").addClass("input-radius");
  }, []);
  return (
    <div className="form-group col-md-4">
      <label className="al-subtitle">
        {inputLabel}&nbsp;
        {toolTipLabel && <Tooltips title={toolTipLabel} />}
      </label>
      <input
        type="text"
        className="form-control  input-radius"
        defaultValue={inputValue}
        disabled
      />
    </div>
  );
};

export const InputAmountFacility = (props) => {
  const { inputLabel, inputValue, toolTipLabel, currency } = props;

  useEffect(() => {}, []);

  return (
    <div className="row form-group col-md-6">
      <div className="col-md-4 text-left">
        <label className="al-subtitle">
          {inputLabel}&nbsp;
          {toolTipLabel && <Tooltips title={toolTipLabel} />}
        </label>
      </div>
      <div className="col-md-7">
        <NumberFormat
          className="form-control"
          value={inputValue ? inputValue : 0}
          displayType="text"
          disabled
          thousandSeparator={true}
          prefix={currency ? currency + " " : ""}
        />
      </div>
    </div>
  );
};
export const InputAmountFacilityPENDING = (props) => {
  const { inputLabel, inputValue, toolTipLabel, currency } = props;

  useEffect(() => {
    $("select, input").addClass("input-radius");
  }, []);

  return (
    <div className="form-group col-md-4">
      <label className="al-subtitle">
        {inputLabel}&nbsp;
        {toolTipLabel && <Tooltips title={toolTipLabel} />}
      </label>
      <NumberFormat
        className="form-control  input-radius"
        value={inputValue ? inputValue : 0}
        displayType="text"
        disabled
        thousandSeparator={true}
        prefix={currency ? currency + " " : ""}
      />
    </div>
  );
};
export const AmntFormatFac = (props) => {
  const { inputValue, currency } = props;

  return (
    <NumberFormat
      value={inputValue}
      displayType="text"
      thousandSeparator={true}
      prefix={currency ? currency + " " : ""}
    />
  );
};

export const Col12BlockFacility = (props) => {
  const { children, topTitle } = props;
  return (
    <div className="col-sm-12">
      {topTitle && (
        <>
          <label className="al-heading">
            <i className="fa fa-circle-o text-navy"></i>&nbsp; {topTitle}
          </label>
          {/* <div className="hr-line-dashed" style={{ marginTop: 0 }} /> */}
          <div className="hr-line-solid-v2" style={{ marginTop: 0 }} />
        </>
      )}
      {children}
    </div>
  );
};

export const ColBlockFac = (props) => {
  const { children, topTitle, colNo } = props;
  return (
    // <div className="col-md-12">
    <div className={`col-sm-${colNo ? colNo : "12"}`}>
      {topTitle && (
        <>
          <label className="al-heading">
            <i className="fa fa-circle-o text-navy"></i>&nbsp; {topTitle}
          </label>
          <div className="hr-line-solid-v2" style={{ marginTop: 0 }} />
        </>
      )}
      {children}
      {/* </div> */}
    </div>
  );
};

export const CreateInputFac = (props) => {
  const { inputLabel, children, fieldType, requiredLabel, dynamicClass } =
    props;
  return (
    <>
      {(fieldType.toLowerCase() === "text" ||
        fieldType.toLowerCase() === "date" ||
        fieldType.toLowerCase() === "number") && (
        <div className={`row form-group col-md-6 ${dynamicClass}`}>
          <div className="col-sm-4">
            <label className="al-subtitle">
              {inputLabel} &nbsp;
              {requiredLabel ? <span className="asterisks">*</span> : ""}
            </label>
          </div>
          <div className="col-sm-7">{children}</div>
        </div>

        // <div className="form-group col-md-6 ">
        //   <label className="col-sm-6 control-label">
        //     {inputLabel} &nbsp;
        //     {requiredLabel ? <span className="asterisks">*</span> : ""}
        //   </label>
        //   <div className="col-sm-5">{children}</div>
        // </div>
      )}

      {fieldType.toLowerCase() === "select" && (
        <div className={`row form-group col-md-6 ${dynamicClass}`}>
          <div className="col-md-4 text-left">
            <label className="al-subtitle">
              {inputLabel} &nbsp;
              {requiredLabel ? <span className="asterisks">*</span> : ""}
            </label>
          </div>
          <div className="col-md-7">{children}</div>
        </div>

        // <div className="form-group col-md-6 ">
        //   <label className="col-sm-6 control-label">
        //     {inputLabel} &nbsp;
        //     {requiredLabel ? <span className="asterisks">*</span> : ""}
        //   </label>
        //   <div className="col-sm-5">{children}</div>
        // </div>
      )}
    </>
  );
};

export const TableHeader = (props) => {
  const { thData } = props;
  return (
    <>
      <thead>
        <tr>
          <th></th>
          {thData.map((item, index) => (
            <th key={index}>{item.name}</th>
          ))}
        </tr>
      </thead>
    </>
  );
};
export const TableBody = (props) => {
  const { tbodyData, tdData, children } = props;
  console.log(props);
  return (
    <>
      <tbody>
        {tbodyData.map((item, index) => (
          <tr className="gradeX" key={index}>
            <td>
              <label className="myradio-button">
                <input
                  type="radio"
                  name="radio"
                  // onClick={() => onSelectCharge(charge)}
                />
                <span className="label-visible">
                  <span className="myfake-radiobutton"></span>
                </span>
              </label>
            </td>
            {children}
          </tr>
        ))}
      </tbody>
    </>
  );
};
export const TableFooter = (props) => {
  const { thData } = props;
  return (
    <>
      <thead>
        <tr>
          <th></th>
          {thData.map((item, index) => (
            <th key={index}>{item.name}</th>
          ))}
        </tr>
      </thead>
    </>
  );
};

export const TableFacility = (props) => {
  const { children } = props;
  // console.log(children);
  return (
    <div className="table-responsive">
      <table
        id="myTable"
        className="table table-striped table-bordered table-hover"
      >
        <TableHeader {...props} />
        <tbody>{children}</tbody>
        <TableFooter {...props} />
      </table>
    </div>
  );
};
export const TableFacility2 = (props) => {
  const { children } = props;
  // console.log(children);
  return (
    <div className="table-responsive">
      <table className="myTable table table-striped table-bordered table-hover">
        <TableHeader {...props} />
        <tbody>{children}</tbody>
        <TableFooter {...props} />
      </table>
    </div>
  );
};

export const TableSimpleFac = (props) => {
  const { thData, children } = props;
  return (
    <div className="col-md-12">
      <div className="table-responsive">
        <table
          className="footable table table-stripped toggle-arrow-tiny"
          data-page-size="10"
          data-filter="#filter"
        >
          <thead>
            <tr>
              <th>#</th>
              {thData.map((item, index) => (
                <th key={index}>{item.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
          <tfoot>
            <tr>
              <td colSpan={thData.length + 1}>
                <ul className="pagination pull-right"></ul>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export const IboxTitleFacility = (props) => {
  const { titleLeft, color, icon, titleRight, tiles } = props;
  return (
    <div className="ibox-title">
      <h5>{titleLeft}</h5>
      <div className="ibox-tools">
        <a className="dropdown-toggle" data-toggle="dropdown">
          <span className={`badge badge-${color}`}>
            <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight} &nbsp;
            <i className="fa fa-chevron-down"></i>
          </span>
        </a>
        <ul className="dropdown-menu dropdown-user">
          {tiles.map((tile, tileIndex) => {
            if (tile.title !== titleRight) {
              return (
                <li key={tileIndex}>
                  <a onClick={() => tile.callback(tile)}> {tile.title}</a>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export const NoDataFac = (props) => {
  const { arrList, title } = props;
  return (
    <>
      {arrList.length === 0 && (
        <div className="alert alert-warning text-center">
          <a className="alert-link">
            {appLabel.noDataMsg} {title}
          </a>
        </div>
      )}
    </>
  );
};

export const NavTabFac = (props) => {
  const { tabsList } = props;
  return (
    <ul className="nav nav-tabs">
      {tabsList.map((item, index) => (
        <li key={index} className={` ${item.isActive ? "active" : ""}`}>
          <a data-toggle="tab" href={item.ref}>
            <i className={item.icon} />
            &nbsp;{item.listName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export const AppModalFac = (props) => {
  const { handleSave, handleClose, show, children, title, saveTitle } = props;
  const showHideClassName = show ? "" : "hide";

  useEffect(() => {}, []);

  return (
    <div>
      <Modal.Dialog
        className={`animated flipInX modal-md ${showHideClassName}`}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <div className="text-right">
            <Button
              className="btn btn-danger btn-xs"
              onClick={() => handleClose()}
            >
              {appLabel.modalDoneBtn}
            </Button>
            &nbsp;
            <Button
              className="btn btn-primary btn-xs"
              onClick={() => handleSave()}
            >
              {" "}
              <i className="fa fa-plus-circle"></i>&nbsp;{" "}
              {appLabel.modalSaveBtn}
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="row">{children}</div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

export const BgImgFac = (props) => {
  const { img, classImg } = props;
  return (
    <img className={classImg ? classImg : "auth-bg"} src={img ? img : Bg} />
  );
};
export const SubmitBtnLoad = (props) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { isLoading, btnName, isFullWidth, icon } = props;

  return (
    <button
      type="submit"
      className={` ${isFullWidth ? "block full-width" : ""} btn btn-${
        isLoading ? "white" : "primary-cutomiz"
      }`}
      disabled={isLoading}
      style={{
        marginTop: isFullWidth && 30,
        boxShadow: isFullWidth && "0 10px 6px -6px #fff",
      }}
    >
      {isLoading && (
        <span style={{ color: "#000", fontWeight: "600" }}>
          <i className="fa fa-spinner fa-spin" /> &nbsp; {appLabel.processing}
        </span>
      )}
      {!isLoading && (
        <span>
          <i className={icon} /> &nbsp; {btnName}
        </span>
      )}
    </button>
  );
};
export const SubmitBtnLoadTwo = (props) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { isLoading, btnName, isFullWidth, icon } = props;

  return (
    <button
      type="submit"
      className={` ${isFullWidth ? "block full-width" : ""} btn btn-${
        isLoading ? "white" : "primary"
      }`}
      disabled={isLoading}
      style={{
        marginTop: isFullWidth && 30,
        boxShadow: isFullWidth && "0 10px 6px -6px #fff",
      }}
    >
      {isLoading && (
        <span style={{ color: "#000", fontWeight: "600" }}>
          <i className="fa fa-spinner fa-spin text-light-green" /> &nbsp;{" "}
          {appLabel.processing}
        </span>
      )}
      {!isLoading && (
        <span>
          <i className={icon} /> &nbsp; {btnName}
        </span>
      )}
    </button>
  );
};
export const OnClickBtnLoad = (props) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { isLoading, btnName, isFullWidth, icon, callbackFn, processMsg } =
    props;

  return (
    <button
      type="button"
      onClick={callbackFn ? () => callbackFn() : null}
      className={` ${isFullWidth ? "block full-width" : ""} btn btn-${
        isLoading ? "warning" : "primary"
      }`}
      disabled={isLoading}
      style={{
        marginTop: isFullWidth && 30,
        boxShadow: isFullWidth && "0 10px 6px -6px #fff",
      }}
    >
      {isLoading && (
        <span style={{ color: "#000", fontWeight: "600" }}>
          <i className="fa fa-spinner fa-spin" /> &nbsp;{" "}
          {processMsg ? processMsg : appLabel.processing}
        </span>
      )}
      {!isLoading && (
        <span>
          <i className={icon} /> &nbsp; {btnName}
        </span>
      )}
    </button>
  );
};
export const handleSkeletonFac = () =>
  Array(6)
    .fill()
    .map((item, index) => (
      <div className="col-xs-6 col-md-6 col-lg-3" key={index}>
        <div className="ibox">
          <div className="ibox-content dashCard">
            <h5 className="m-b-md">
              <Skeleton circle={true} inline={true} height={20} width={20} />
              <Skeleton
                style={{ width: "40%", left: "10px", fontSize: "10px" }}
              />
            </h5>
            <h3>
              <Skeleton circle={true} inline={true} height={20} width={20} />
              <Skeleton
                style={{ width: "80%", left: "10px", fontSize: "10px" }}
              />
            </h3>
            <span>
              <Skeleton style={{ width: "40%", fontSize: "10px" }} />
            </span>
          </div>
        </div>
      </div>
    ));
export const HandleDashSkltFac = (length) =>
  Array(length)
    .fill()
    .map((item, index) => (
      <div className="col-xs-6 col-md-6 col-lg-4" key={index}>
        <div className="ibox">
          <div className="ibox-content dashboard-card-loader">
            <h5 className="m-b-md">
              <Skeleton circle={true} inline={true} height={20} width={20} />
              <Skeleton
                style={{ width: "40%", left: "10px", fontSize: "10px" }}
              />
            </h5>
            <h3>
              <Skeleton circle={true} inline={true} height={20} width={20} />
              <Skeleton
                style={{ width: "80%", left: "10px", fontSize: "10px" }}
              />
            </h3>
            <span>
              <Skeleton style={{ width: "40%", fontSize: "10px" }} />
            </span>
          </div>
        </div>
      </div>
    ));
export const HandleSkeletonTbleFac = (props) => {
  const { classParam } = props;
  return (
    <div className={`table-responsive ${classParam}`}>
      <table className="myTable table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th></th>
            <th>
              <Skeleton />
            </th>
            <th>
              <Skeleton />
            </th>
            <th>
              <Skeleton />
            </th>
            <th>
              <Skeleton />
            </th>
          </tr>
        </thead>
        <tbody>
          {Array(5)
            .fill()
            .map((item, index) => (
              <tr key={index}>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th>
              <Skeleton />
            </th>
            <th>
              <Skeleton />
            </th>
            <th>
              <Skeleton />
            </th>
            <th>
              <Skeleton />
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
export const HandleSkeletonMenuFac = (props) => {
  return Array(5)
    .fill()
    .map((item, index) => (
      <li key={index}>
        <a>
          <Skeleton
            baseColor={"#BED600"}
            circle={true}
            inline={true}
            height={20}
            width={20}
          />
          <Skeleton
            baseColor={"#BED600"}
            style={{ width: "40%", left: "10px", fontSize: "10px" }}
          />
        </a>
      </li>
    ));
};

export const HandleSkeletonDetailFac = (props) => {
  const { classParam } = props;

  return (
    <>
      <div className={`ibox ${classParam}`}>
        <div className="ibox-title">
          <div className="ibox-tools">
            <Skeleton
              style={{ width: "10%", right: "10px", fontSize: "20px" }}
            />
          </div>
        </div>
        <div className="ibox-content contentCard panel-primary">
          <div className="row">
            <div className="col-sm-12">
              <Skeleton
                style={{ width: "40%", left: "10px", fontSize: "10px" }}
              />
              <div className="hr-line-dashed" style={{ marginTop: 0 }} />

              {Array(8)
                .fill()
                .map((item, index) => (
                  <div className="row form-group col-md-6" key={index}>
                    <div className="col-md-10">
                      <Skeleton
                        style={{
                          width: "100%",
                          left: "40px",
                          fontSize: "30px",
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const BackBtnFac = (props) => {
  const { urlParam, labelParam, icon, btnClass } = props;

  // const url = urlParam ? urlParam :
  return (
    <a
      style={{ margin: "0" }}
      onClick={() => {
        urlParam ? history.push(urlParam) : history.goBack();
      }}
      className={`btn  btn-rounded btn-md m-r ${
        btnClass ? btnClass : "__btn-primary"
      }`}
    >
      <i className={`fa fa-${icon ? icon : "arrow-circle-left"}`} />
      &nbsp;{labelParam ? labelParam : appLabel.backBtn}
    </a>
  );
};

export const HandleReqFldMsg = (key) => {
  return key + " is required.";
};

export const OutlineFldErr = (key) => {
  return key ? "outline-error" : "";
};

export const NewRecordStatusdata = () => {
  return {
    FLG_STATUS: "U",
    MAKER_ID: "",
    MAKER_DATE: BOM.CurrentDate(),
    CHECKER_ID: "Not Applicable",
    CHECKER_DATE: "Not Applicable",
  };
};
