import { cnt } from "../../constant.action";

const INITIAL_STATE = {
  isSignedIn: false,
  userToken: false,
  userData: false,
  configData: {},
  lockscreenData: {
    username:null,
    profileFirstName:null,
    profileLastName:null,
  },
  authBuffer:""
};


export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case cnt.SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        userToken: payload.session,
        userData: payload,
        lockscreenData:{
          username:payload.userName,
          firstName:payload.profileFirstName,
          lastName:payload.profileLastName,
          fullName: payload.profileFirstName + ' ' + payload.profileLastName
        }
      };
      case cnt.AUTH_BUFFER:
        return {
          ...state,
          authBuffer: payload
        };
    case cnt.SIGN_OUT:
      // initiate the whole of object.
      return { ...INITIAL_STATE };
      case cnt.LOCKSCREEN:
      // initiate the part of object.
      return {
        ...state,
        isSignedIn: false,
        userToken: false,
        userData: false,
        configData: {},
      };
    default:
      return state;
  }
};
