import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { cnt } from "../../services/constant.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import {
  Tooltips,
  ColBlockFac,
  NoDataFac,
  BackBtnFac,
  OutlineFldErr,
} from "../layouts/Facility";
import GlobalDetailScreen from "../layouts/GlobalDetailScreen";

export const DetailAccountDeregistration = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const { location, userData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session: sessionId, userRoleId } = userData;
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState({});
  const [inputFldData, setInputFldData] = useState([]);
  const [isComment, setIsComment] = useState(true);
  const [innerRecordStatus, setInnerRecordStatus] = useState(
    "Loading Record Status...."
  );
  const [showHide, setShowHide] = useState({
    isReject: false,
    isMaker: userRoleId === "RL0000000000010" ? true : false,
    isDisabled: false,
    isChecked: false,
    isApproved: false,
    isRejected: false,
    isForOfficialUsed: false,
  });
  const { HandleFlgStatus} = appStatic;


  useEffect(() => {
    getDetailById(ggParam);
  }, []);

  const getDetailById = (params) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      userId: params.userId,
      sessionId,
    };

    BOM.FetchReqAction(body, api.GetUserDetails, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
      } else {
        BOM.LoadTimeout();
        let userDetail = res.data;

        setUserDetail(userDetail);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: userDetail,
        });

        setValue("reason", userDetail.comments);
        // check if company or individual
        const isCompanyType = userDetail?.clientType?.toLowerCase() === "company"  ? true : false

        setShowHide({
          ...showHide,
          isCompanyClientType:isCompanyType,
        });

        setInputFldData([
          {
            name: appLabel.profileId,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.userId,
            },
          },
          {
            name: appLabel.username,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.username,
            },
          },
          {
            name: appLabel.firstName,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.userFirstname,
            },
          },
          {
            name: appLabel.lastName,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.userLastName,
            },
          },
          {
            name: appLabel.status,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: HandleFlgStatus(userDetail.flgStatus),
            },
          },
          {
            name: isCompanyType ? appLabel.companyAccountName : appLabel.accountName,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.accountName,
            },
          },
          {
            name: isCompanyType ? appLabel.companyAccountNo : appLabel.accountNumber,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.accountNumber,
            },
          },
          {
            name: isCompanyType ? appLabel.companyTin : appLabel.tin,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.tin,
            },
          },
          {
            name: appLabel.phoneNumber,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.userPhoneNumber,
            },
          },
          {
            name: appLabel.idType,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.idNumberTypeName,
            },
          },

          {
            name: `${userDetail.idNumberTypeName} Number`,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.idNumber,
            },
          },
          {
            name: appLabel.currency,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.accountCurrency,
            },
          },
          {
            name: appLabel.clientType,
            fldType: "text",
            section: appLabel.secBank,
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: userDetail.clientType,
            },
          },
        ]);

        // RECORD STATUS SHOW HIDE AND RIGHT BUTTON
        switch (userRoleId) {
          case "RL0000000000010": // maker
            setShowHide({
              ...showHide,
              isMaker: true,
            });

            switch (userDetail.flgStatus.toLowerCase()) {
              case "d":
                BOM.IsApproveOrReject("none", menuData, "Manage Account");
                setInnerRecordStatus(
                  <RecordStatus
                    {...{
                      FLG_STATUS: userDetail.flgStatus,
                      MAKER_ID: userDetail.makerIdDeregistration,
                      MAKER_DATE: userDetail.makerDateDeregistration,
                      CHECKER_ID: "Not Applicable",
                      CHECKER_DATE: "Not Applicable",
                    }}
                  />
                );
                break;
              default: // ELSE STATUS IS P
                BOM.IsApproveOrReject("u", menuData, "Manage Account");
                setInnerRecordStatus(
                  <RecordStatus
                    {...{
                      FLG_STATUS: "U",
                      MAKER_ID: "",
                      MAKER_DATE: "",
                      CHECKER_ID: "Not Applicable",
                      CHECKER_DATE: "Not Applicable",
                    }}
                  />
                );
                break;
            }
            break;
          default:
            BOM.IsApproveOrReject("none", menuData, "Manage Account");
            switch (userDetail.flgStatus.toLowerCase()) {
              case "d":
                setInnerRecordStatus(
                  <RecordStatus
                    {...{
                      FLG_STATUS: userDetail.flgStatus,
                      MAKER_ID: userDetail.makerIdDeregistration,
                      MAKER_DATE: userDetail.makerDateDeregistration,
                      CHECKER_ID: "Not Applicable",
                      CHECKER_DATE: "Not Applicable",
                    }}
                  />
                );
                break;

              default:
                setInnerRecordStatus("");
                break;
            }
            break;
        }
        switch (userDetail.flgStatus.toLowerCase()) {
          case "p":
            setShowHide({
              ...showHide,
              isForOfficialUsed: true,
              isDisabled: true,
            });
            break;

          default:
            setShowHide({
              ...showHide,
              isForOfficialUsed: true,
              isDisabled: true,
            });
            break;
        }
      }
    });
  };
  const OnError = (errors) => {
    setShowHide({ ...showHide, isReject: true, isDisabled: false });
    BOM.AlertMsg(cnt.WARNING, errors.reason.message);
  };
  const onSelect = (event, key) => {
    let check = event.target.checked;
    switch (key.toLowerCase()) {
      case "checker-verification":
        setShowHide({ ...showHide, isChecked: check });
        break;

      default:
        break;
    }
  };
  const onApprove = () => {
    let body = {
      profileId,
      emailAddress: ggParam.username,
      userId: ggParam.userId,
      phoneNumber:ggParam.phoneNumber,
      status: "D",
      sessionId,
    };

    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectCustomer,
      "dashboard",
      `${ggParam.userFirstname} ${ggParam.userLastname} deregistration request.`,
      "Approve "
    );
  };
  const onReject = (formValues) => {
    if (userDetail.comments === formValues.reason) {
      setValue("reason", "");
    }

    if (!getValues("reason")) {
      setShowHide({
        ...showHide,
        isForOfficialUsed: true,
        isReject: true,
        isMaker: false,
        isChecked: false,
        isDisabled: false,
      });

      BOM.AlertMsg(cnt.WARNING, BOM.CheckReqFld(appLabel.rejectReason));
      return;
    }
    formValues.status = "N";

    let body = {
      profileId,
      userId: ggParam.userId,
      emailAddress: ggParam.username,
      phoneNumber:ggParam.phoneNumber,
      ...formValues,
      sessionId,
    };

    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectCustomer,
      "dashboard",
      `${ggParam.userFirstname} ${ggParam.userLastname} deregistration request.`,
      "Reject "
    );
  };

  return (
    <Main
      {...props}
      onApprove={onApprove}
      onReject={handleSubmit(onReject, OnError)}
    >
      <form autoComplete="off">
        <ContentTop pageTitle={appLabel.accountDetail} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-content content-card-v2">
                  <div className="row">
                    <ColBlockFac>
                      <BackBtnFac />
                    </ColBlockFac>
                    <div className="col-sm-2">
                      <div className="text-center">
                        <img
                          src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                          className="avatar img-circle img-thumbnail"
                          alt="avatar"
                        />
                      </div>
                      <br />
                    </div>
                    <div className="col-sm-10">
                      <GlobalDetailScreen
                        arrayData={inputFldData}
                        topTitleParam={appLabel.customerInfo}
                        sectionParam={appLabel.secGeneral}
                      />
                      <GlobalDetailScreen
                        arrayData={inputFldData}
                        topTitleParam={appLabel.accountInformation}
                        sectionParam={appLabel.secBank}
                      />
                      {showHide.isForOfficialUsed && (
                        <ColBlockFac topTitle={"FOR OFFICIAL USE ONLY"}>
                          <div className="row form-group col-md-12">
                            <div className="col-md-2 text-left">
                              <label className="al-subtitle">
                                {"Reason/Comment"}&nbsp;
                                <Tooltips
                                  title={
                                    "When rejecting a user, a reason or comment is required."
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-md-8">
                              <textarea
                                disabled={showHide.isDisabled}
                                style={{
                                  width: "100%",
                                  height: 100,
                                  padding: 5,
                                }}
                                className={`form-control ${OutlineFldErr(
                                  errors.reason
                                )}`}
                                placeholder={"Reason / Comment..."}
                                {...register("reason", {
                                  required: isComment
                                    ? BOM.CheckReqFld(appLabel.rejectReason)
                                    : false,
                                  minLength: {
                                    value: 1,
                                    message: "Minimum length is 1 character",
                                  },
                                  maxLength: {
                                    value: 200,
                                    message: "Maximum length is 200 characters",
                                  },
                                })}
                              />
                              <span className="asterisks">
                                {errors?.reason?.message}
                              </span>
                            </div>
                          </div>
                        </ColBlockFac>
                      )}
                    </div>
                  </div>
                  {innerRecordStatus}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  recordData: state.recordStatus,
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailAccountDeregistration);
