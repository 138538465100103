import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './other_files/reportWebVitals';
import {notifInitialization} from "./services/defined.action"
// import 'boxicons'
/** DECLARED GLOBAL VARIABLES */
  window.globalData = {
    addNewLink:"",
    viewLink:"",
    editLink:"",
  }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Initialize notiflix popup
notifInitialization();
