export default {
  isProduction: false,
  baseURL: {
    // dev:"http://35.178.127.132:8080/ecobank/esc/rra/v1/api",
    dev: "/ecobank/esc/rra/v1/api",
    prod: "",
  },
  apiSettings: {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      uikey: "",
    },
  },
};
