import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import Main from "../../components/layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import NumberFormat from "react-number-format";
import { apis as api } from "../../services/api.action";
import {
  HandleDashSkltFac,
  HandleSkeletonTbleFac,
  ColBlockFac,
  TableFacility,
} from "../../components/layouts/Facility";

export const Dashboard = (props) => {
  return (
    <Main {...props}>
      {/* <ContentTop pageTitle={appLabel.dashboard} /> */}
      <div className="m-t" style={{ marginTop: "5%" }}>
        <InnerRender {...props} />
      </div>
    </Main>
  );
};

function InnerRender(props) {
  const { userData, menuData, location, history } = props;
  const { profileId, session: sessionId, userRoleId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [dashboadData, setDashboadData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { DashboardData, tableData, HandleFlgStatus, HandleFlgStatusIcon } = appStatic;
  const [isMaker, setIsMaker] = useState(
    userRoleId === "RL0000000000010" ? true : false
  );
  const [isChecker, setIsChecker] = useState(
    userRoleId === "RL0000000000011" ? true : false
  );
  const [isCustodian, setIsCustodian] = useState(
    userRoleId === "RL0000000000009" ? true : false
  );
  const [usersData, setUsersData] = useState([]);

  const customerData = [
    {
      date: "2023-06-28",
      firstName: "Customer F Name 1",
      lastName: "Customer L Name 1",
      phone: "+250 xxxxxx",
      status: "Pending",
      icon: "fa fa-clock-o text-warning",
    },
    {
      date: "2023-06-28",
      firstName: "Customer F Name 2",
      lastName: "Customer L Name 2",
      phone: "+250 xxxxxx",
      status: "Pending",
      icon: "fa fa-clock-o text-warning",
    },
    {
      date: "2023-06-30",
      firstName: "Customer F Name 3",
      lastName: "Customer L Name 3",
      phone: "+250 xxxxxx",
      status: "Pending",
      icon: "fa fa-clock-o text-warning",
    },
    {
      date: "2023-07-04",
      firstName: "Customer F Name 4",
      lastName: "Customer L Name 4",
      phone: "+250 xxxxxx",
      status: "Pending",
      icon: "fa fa-clock-o text-warning",
    },
  ];
  useEffect(() => {
    getListByCriteria();
  }, []);

  function getListByCriteria() {
    setIsLoading(true);

    let body = {
      profileId,
      sessionId,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDashboardCounts, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        setIsLoading(false);
      } else {
        const objDetail = res.data;
        setDashboadData(DashboardData(userRoleId, objDetail));
        BOM.DatatableUsage();
        getAccountList()
        setIsLoading(false);
      }
    });
  }
  function getAccountList() {
    
    let body = {
      profileId,
      criteria:"G",
      recordFrom: "0",
      recordTo: "10",
      sessionId,
    };

    setIsLoading(true);
    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCustomerList, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        setIsLoading(false);
      } else {
        // console.log(res);
        setUsersData(res.data);
        BOM.DatatableUsage();
        setIsLoading(false);
      }
    });
  }
  return (
    <>
      <div className="animated fadeInRightFAKE">
        <div className="row">
          {isLoading ? (
            HandleDashSkltFac(3)
          ) : (
            <>
              {dashboadData.length === 0 && (
                <div className="col-md-12" style={{ marginTop: "20%" }}>
                  <div className="alert alert-info text-center">
                    <a className="alert-link">{appLabel.noDataAvailableMsg}</a>
                  </div>
                </div>
              )}
              {dashboadData.map((item, index) => (
                <div
                  key={index}
                  className={`${item.dashDisplay} ${
                    (isChecker || isMaker) && `col-sm-4 col-lg-4`
                  } ${isCustodian && "col-sm-4 col-lg-4"}`}
                >
                  <div className={`ibox`}>
                    <Link
                      to={{
                        pathname: `/${item.linkUrl}`,
                        state: {
                          ggParam: item,
                        },
                      }}
                    >
                      <div className="ibox-content dashCard">
                        <h5 className="m-b-md">
                          <i className="fa fa-book text-light-green" /> &nbsp;{" "}
                          {item.itemType}
                        </h5>
                        <h3>
                          <i
                            className={`fa fa-${item.icon} text-light-green`}
                          />
                          &nbsp; {item.name}
                        </h3>
                        <h6>{item.value}</h6>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <div className="animated fadeInRightFAKE">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-content panel-primary content-card-v3">
                  <ColBlockFac>
                    <div className="ibox-tools m-b">
                      <a className="dropdown-toggle" data-toggle="dropdown">
                        <span
                          className={`btn __btn-primary btn-rounded btn-sm`}
                        >
                          <i className={`fa fa-clock-o text-light-green`}></i>{" "}
                          &nbsp; {appLabel.recentAccountActivities}
                        </span>
                      </a>
                    </div>
                  </ColBlockFac>
                  <div className="col-lg-12 m-t">
                    <HandleSkeletonTbleFac
                      classParam={`${isLoading ? "" : "hide"}`}
                    />
                    <div className={` ${isLoading ? "hide" : ""}`}>
                      {customerData.length === 0 ? (
                        <div className="alert alert-warning text-center">
                          <a className="alert-link">
                            {appLabel.noDataInTableMsg}
                          </a>
                        </div>
                      ) : (
                        <TableFacility thData={tableData.ListAccountDashboard}>
                        {usersData.map((item, index) => (
                          <tr className="gradeX" key={index}>
                            <td>
                              {index + 1}
                            </td>
                            <td>{item.userId}</td>
                            <td>{item.userFirstname}</td>
                            <td>{item.userLastname}</td>
                            <td>{item.username}</td>
                            <td className="center">{HandleFlgStatusIcon(item.flgStatus)} {HandleFlgStatus(item.flgStatus)}</td>
                          </tr>
                        ))}
                      </TableFacility>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
