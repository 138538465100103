const OWNER = "RRA";
const appLabel = {
  companyInformation: "Company Information",
  contactInformation: "Contact Information",
  principalContactInformation: "Principal Contact Information",
  bankInformation: "Bank Information",
  investorInfo: "Investor Info",
  roleInformation: "Role Information",
  menuInformation: "Menu Information",
  generalInfo: "General Information",
  addressInfo: "Address Information",
  otherInfo: "Additional Information",
  contactInfo: "Contact Information",
  orderInfo: "PO Information",
  materialInfo: "Material Info",
  financeInformation: "Finance Information",
  batchInfo: "Batch Information",
  taxInformation: "Tax Information",
  trustAccountInformation: "DPA Information",
  bankInformation: "Bank Information",
  coBankInfo: "Company Bank Information",
  currencyInfo: "Currency Information",
  surchargeInfo: "Transaction Fee Information",
  contractInformation: "Contract Information",
  orderFinancingInfo: "PO Financing Information",
  invoiceFinancingInfo: "Invoice Financing Information",
  otherPoFeesInfo: "Other Fees Information",
  feeInfo: "Fee Information",
  dateInfo: "Date Information",
  otherFeesInfo: "PO Financed Information",
  invoiceInformation: "Invoice Information",
  userInformation: "User Information",
  paymentInformation: "Payment Information",
  discountInfo: "Discount Information",
  invoiceUploadInfo: "Invoice Upload Information",
  loanInfo: "Loan Information",
  invoiceDetail: "Invoice Detail",
  newShareInvoice: "New Share Invoice",
  shareInvoice: "Share Invoice",
  sharedInvoice: "Available Invoice",
  invoiceUpload: "Invoice Upload",
  newInvoiceUpload: "New Invoice Upload",
  invoiceUploadDetail: "Invoice Upload Detail",
  invoiceTenorMonth: "Tenor(Month)",

  username: "Username",
  emailAddress: "Email Address",
  password: "Password",
  inputAccountNumber: "Input Account Number",
  inputAccountDetails: "Input Account Details",
  accountNumber: "Account Number",
  btnLogin: "Login",
  forgotpass: "Forgot Password",
  newTo: `New to ${OWNER} ? `,
  welcomeTo: "Welcome to ",
  owner: OWNER,
  createAccount: "Register Now",
  currentPwd: "Current Password",
  newPwd: "New Password",
  currentPwd:"Current Password",
  confirmPwd: "Confirm Password",
  resetPwd: "Reset Password",
  changePwd: "Change Password",
  changePwdInfo: "Change Password Information",
  proceedBtn: "Proceed",
  continueBtn: "Continue",
  confirmEmail: "Confirm Email",
  goToLogin: "Back to Login",
  lockScreenMessage:
    "Your are in lock screen. You need to enter your password to go back to the app.",
  unlockBtn: "Unlock",
  lockscreen: "Lock Screen",
  logout: "Log out",
  unlockBtn: "Unlock",

  poCardTop: "PO",
  invoiceCardTop: "Invoice",
  shared: "Shared",
  poVerification: "Verification",

  manageAlias: "Manage Alias",
  manageUser: "Manage User",
  generateMpesaAlias: "Generate M-Pesa Alias",
  newAlias: "New Alias",
  companyDetail: "Company Detail",
  editCompany: "Edit Company",
  systemAdmin: "System Admin",
  companyId: "Company ID",
  companyName: "Company Name",
  companyRegNo: "Registration No",
  vatId: "VAT ID",
  website: "Website",
  tradingName: "Trading Name",
  taxId: "Tax ID",
  companyEmail: "Email",
  companyType: "Company Type",
  companyTypeId: "Company Type ID",
  companyTypeName: "Company Type Name",
  newCompany: "New Company",
  primaryContact: "Primary Contact",
  otherContact: "Secondary Contact",
  contactName: "Contact Name",
  officePhone: "Office Phone",
  contactRole: "Contact Role",
  contactEmail: "Contact Email",
  primaryAddress: "Primary Address",
  shippingAddress: "Shipping Address",
  billingAddress: "Billing Address",
  shipBuyerAddress: "Shipping ( Buyer )",
  bilBuyerAddress: "Billing ( Buyer )",
  shipSupplierAddress: "Shipping ( Supplier )",
  bilSupplierAddress: "Billing ( Supplier )",
  sameAsPrimaryAddress: "Same as Primary Address",
  companyRegDate: "Incorporation Date",
  businessStartDate: "Business Start Date",
  businessType: "Business Type",
  principalContact: "Principal Contact",
  phoneNumber: "Phone Number",
  gender: "Gender",
  female: "Female",
  male: "Male",
  bankName: "Bank Name",
  accountName: "Account Name",
  accountNumber: "Account Number",
  accountDetails: "Account Details",
  branchCode: "Branch Code",
  accountType: "Account Type",
  investorName: "Investor Name",
  investorId: "Investor ID",
  investorType: "Investor Type",
  business: "Business",
  individual: "Individual",
  bvnRcNumber: "BVN",
  clientTitle: "client Title",
  clientType: "Client Type",
  contactAddress: "Contact Address",
  dateOfBirth: "Date of Birth",
  principalContact: "Principal Contact",
  investedAmount: "Invested Amount",
  clientBPID: "Client BPID",
  custodianBPID: "Custodian BPID",
  userNameExemple: "( example@domain.com )",
  emailAddressExample: "( example@domain.com )",
  financeYear: "Financial Year",
  flgStatusName: "Status",
  documentType: "Doc Type",
  documentUpload: "Doc Upload",
  documentInfo: "Document Info",
  manageRole: "Manage Role",
  roleDetail: "Role Detail",
  roleId: "Role ID",
  roleName: "Role Name",
  roleDescription: "Role Description",
  search: "Search",

  roleMenu: "Role Config",
  roleConfig: "Role Config",
  newSubrole: "New SubRole",
  newRole: "New Role",
  editRole: "Edit Role",
  parentRoleName: "Parent Role Name",
  // newMenu: "Entity Config Setup",
  newMenu: "Role Config Setup",
  addToMenu: "Role Config Setup",
  roleMenuDetail: "Role Config Detail",
  readLess: "Minimise",
  entityTypeMenuId: "Entity ID",
  entityTypeMenuName: "Entity Name",
  entityTypeMenuDesc: "Entity Description",
  mpesaPhoneNumber: "M-Pesa Phone Number",
  generateAlias: "Generate Alias",
  inputMpesaPhoneNumber: "M-Pesa Phone Number",
  mpesaAliasNumber: "M-Pesa Alias Number",
  share: "Share",

  editUser: "Edit User Setup",
  userEmail: "Email",
  email: "Email",
  nuban: "Nuban",
  firstName: "First Name",
  lastName: "Last Name",
  yetToConfigure: "Yet to configure.",
  this_menu_has_no_sub_menu: "This menu has no sub menu.",
  has_all_menu_configured: "All menus are configured.",
  You_are_about_to_add_all_config:
    "You are about to add all menus configuration.",
  entityType: "Entity Type",
  entityMenuDetail: "Entity Config Detail",
  create: "Create",
  view: "View",
  edit: "Edit",
  approve: "Approve",
  reject: "Reject",
  recordStatus: "Record Status",
  authStatus: "Auth Status",
  makerId: "Maker ID",
  makerDate: "Maker Date",
  checkerId: "Checker ID",
  checkerDate: "Checker Date",
  authorized: "Authorized",
  unauthorized: "Unauthorized",
  disabled: "Disabled",
  listing: "listing",
  authorizedListing: "Authorized Listing",
  unauthorizedListing: "Unauthorized Listing",
  disabledListing: "Disabled Listing",
  backBtn: "Back",

  statutoryInfo: "Statutory Data",

  country: "Country",
  province: "Province/State",
  city: "City/Town",
  address: "Address",
  address1: "Address 1",
  address2: "Address 2",
  postCode: "Post code",
  choose: "Select",
  noDataInTableMsg: "No data available in table",
  noDocumentMsg: "No document available.",
  entityMenu: "Entity Config",
  entityMenuListing: "Entity Listing",
  viewMore: "View More",

  newPoOrder: "New PO",
  poNo: "PO No",
  poAmt: "PO Amount",
  from: "Buyer",
  to: "Supplier",
  buyer: "Buyer",
  supplier: "Supplier",
  poAmount: "Amount",
  currency: "Currency",
  pymtOption: "Payment Option",
  frequency: "Frequency",
  firstPayDue: "Issue Date",
  lastPayDue: "Expiry Date",
  verificationStatus: "Verif Status",
  date: "Date",

  description: "Description",
  type: "Type",
  unitPrice: "Unit Price",
  quantity: "Quantity",
  manufacturer: "Manufacturer",
  managePo: "Manage PO",
  managePoToVerify: "PO to Verify",
  manageInvoiceToVerify: "Invoice to Verify",
  csdNumber: "Note Number",
  noteName: "Note Name",
  noteCode: "Note Code",
  registrar: "Depository",
  lpoRefId: "LPO Ref ID",
  category: "Category",
  lomCode: "LOM Code",
  orderDetail: "PO Detail",
  shareOrder: "Available PO",
  sharePO: "Share PO",
  fundedPO: "Funded PO",
  fundedPODetail: "Funded PO Detail",
  fundedInvoice: "Funded Invoice",
  fundedInvoiceDetail: "Funded Invoice Detail",
  sharedOrderDetail: "Available PO Detail",
  sharedInvoiceDetail: "Shared Invoice Detail",
  financedOrder: "Quoted PO",
  financedOrderDetail: "Quoted PO Detail",
  financedInvoice: "Quoted Invoice",
  financedInvoiceDetail: "Quoted Invoice Detail",

  verifyOrder: "Confirm Verification",
  confirmVerification: "Confirm Verification",
  confirmFunding: "Confirm Funding",
  verifiedOrder: "Verified PO",
  initiateOrder: "Verify PO",
  initiateInvoice: "Verify Invoice",
  newShareOrder: "New Share PO",
  capitalRequired: "Capital Required",
  fundsAvailable: "Equity Contribution",
  logisticsOtherCosts: "Logistics Costs",
  supplierQuote: "Material Quote",
  orderId: "PO No",
  manageBacth: "Batch Upload",

  batchId: "Batch ID",
  batchDescription: "Batch Description",
  batchNumber: "Batch Number",
  batchName: "Batch Name",
  batchDetail: "Batch Detail",
  newBatch: "New",
  newOrderBatchUpload: "New PO Upload",
  processedBatch: "Processed",
  fileLocation: "File Location",
  uploadFile: "Upload File",
  processingStatus: "Processing Status",

  rate: "Rate (%)",
  rateAmount: "Rate Amount",
  auditTrail: "Audit Trail",
  searchCriteria: "Select Search Criteria",
  startDate: "Start Date",
  endDate: "End Date",
  search: "Search",
  result: "Search Result",
  amount: "Amount",
  effectiveDate: "Effective Date",
  waiverAllow: "Waiver Allow",
  chargeName: "Transaction Name",
  chargeId: "Transaction ID",
  newTax: "New Tax",

  manageTax: "Manage Tax",
  taxId: "Tax ID",
  bankId: "Bank ID",
  taxDetail: "Tax Detail",
  bankDetail: "Bank Detail",
  coBankDetail: "Company Bank Detail",
  code: "code",
  currencyCode: "Currency code",
  isoCode: "ISO Code",
  manageCurrency: "Manage Currency",
  newCurrency: "New Currency",
  newTrustAccount: "New DPA",
  newBank: "New Bank",
  newCoBank: "New Company Bank",
  bankCode: "Bank Code",

  currencyDetail: "Currency Detail",
  trustAccountDetail: "DPA Detail",
  currencyId: "Currency ID",
  trustAccountId: "Depository ID",
  manageSurchage: "Transactional Fee",
  manageTrustAccount: "DPA",
  manageBank: "Manage Bank",
  manageCoBank: "Manage Company Bank",

  newSurcharge: "New Transaction",
  editSurcharge: "Edit Transaction",
  chargeType: "Transaction Type",
  chargeDetail: "Transaction Detail",

  newFee: "New Fee",
  editFee: "Edit Fee",
  feeDetail: "Fee Detail",
  feeInfo: "Fee Information",

  feeName: "Fee Name",
  feeType: "Fee Type",
  feeOption: "Fee Option",
  feePercentage: "Percentage",
  isFeeSlab: "Has Tiered Fees?",
  minAmount: "Min Amount",
  maxAmount: "Max Amount",
  feeAmount: "Fee Amount",
  feeId: "Fee Id",

  manageContract: "Manage Contract",
  newContract: "New Contract",
  contractDetail: "Contract Detail",
  contractType: "Contract Type",

  id: "ID",
  value: "Value",
  obligation: "Obligation",
  counterParty: "Counter Party",
  deliveryDate: "Delivery Date",
  status: "Status",

  auditDate: "Audit Date",
  event: "Event",
  actionPerformed: "Action Performed",

  invoiceId: "Invoice ID",
  contractId: "Contract ID",
  broadcastId: "Broadcast ID",
  fundingId: "Funding ID",
  discountId: "Discount ID",
  funder: "Funder",
  discountedCard: "Discounted",

  orderFinancing: "PO Financing",
  invoiceFinancing: "Invoice Financing",

  AmountToFinance: "Loan Amount",
  interestRate: "Annual Interest Rate (%)",
  chargeRate: "Transaction Rate (%)",
  duration: "Tenor(Month)",
  trustAccount: "DPA",

  insuranceFee: "Insurance Fee",
  interestAmount: "Interest Amount",
  arrangementFees: "Arrangement Fees",
  otherFees: "Other Fees",
  // paymentDate: "Payment Date",
  invPymtDate: "Payment Date",
  invIssueDate: "Issue Date",
  costOfFinance: "Cost of Finance",
  totalRepayment: "Total Repayment",
  installmentAmount: "Installment Amount",
  proceed: "Confirm funding",
  manageInvoice: "Manage Invoice",
  newInvoice: "New Invoice",
  invoiceDetail: "Invoice Detail",

  verified: "Verified",
  unVerified: "Unverified",
  version: "Version 1.0",
  poweredBy: "Powered by FACTOReX",
  poweredBy: "Powered by ",
  factorEx: "FACTOReX",

  materialInfoShort: "Material",
  documentInfoShort: "Document",
  invoiceInfoShort: "Invoice",
  pOInfoShort: "PO",
  contractInfoShort: "Contract",
  financeInfoShort: "Finance",
  itemId: "Item ID",

  originalAmount: "Original Amount",
  discountAmount: "Discount Amount",
  finalAmount: "Final Amount",
  history: "History",
  amountFunded: "Funding Offer",
  amountRequested: "Amount Requested",
  historyType: "Type",
  paymentDate: "Value Date",
  transactionDate: "Transaction Date",
  totalAmount: "Total Amount",
  funded: "Funded",
  notFunded: "Not Funded",

  dueDate: "Due date",
  discountCode: "Discount Code",
  taxType: "Tax Type",
  taxCode: "Tax Code",
  adjustment: "Adjustment",
  taxRate: "Tax Rate",
  taxableAmount: "Tax Amount",
  taxAmount: "Tax Amount",
  buyerTax: "Buyer Tax",
  supplierTax: "Supplier Tax",
  yes: "Yes",
  no: "No",

  discounted: "Discounted (%)",
  discountedAmount: "Discounted Amount",
  netInvoiceAmount: "Net Amount",
  processingFee: "Processing Fee",
  adjustment: "Adjustment",
  remitTo: "Remit To",

  statusPending: "Pending",
  statusFunded: "Funded",
  statusNew: "New",

  pendingListing: "Pending Items",
  fundedListing: "Funded Items",
  newListing: "New Items",
  shipSameAsPrimaryAddress: "Shipping Address is the same as Primary Address",
  billSameAsPrimaryAddress: "Billing Address is the same as Primary Address",
  sameAsPrimary: "Same as Primary",

  reportPOByStatus: "PO by Status",
  reportPOShared: "Available PO",
  reportPOFinanced: "Quoted PO",
  reportInvoices: "Invoices",
  reportSharedInvoices: "Shared Invoices",
  reportDiscountedInvoices: "Quoted Invoice",
  reportContracts: "Contracts",
  report: "Report",
  criteria: "Criteria",

  poNumber: "PO Number",
  poIssuer: "PO Issuer",
  poAmount: "PO Amount",
  poIssueDate: "PO Issue Date",
  poReceipient: "PO Receipient",
  poProposeFunder: "No of Funders",
  dateFunded: "Date Funded",
  fundingAmount: "Funding Amount",
  incomeAmount: "Income Amount",

  manageProspectCompany: "Manage Prospect",
  prospectCompanyDetail: "Prospect Company Detail",
  editProspectCompany: "Edit Prospect Company",

  itemType: "Item Type",
  itemValue: "Item Value",
  coTaxRegNo: "Company/Tax Reg No",

  companyInfo: "Company",
  contactInforma: "Contact",
  saveBtn: "Save",
  cancelBtn: "Cancel",
  resendBtn: "Resend",
  validateOtpBtn: "Validate",
  contactPersonDesign: "Designation ",
  customerType: "Customer Type",
  maxSurcharge: "Maximum Transaction",

  fullName: "Full Name",
  businessIdType: "Business ID Type",

  invoiceNumber: "Invoice Number",
  invoiceIssuer: "Invoice Issuer",
  invoiceAmount: "Invoice Amount",
  invoiceIssueDate: "Invoice Issue Date",
  paymentAmount: "Payment Amount",

  discountDate: "Funded Date",
  discountPercentage: "Discount %",
  maxFileUploadMsg: "*** Maximum file size should be 5 MB ( PDF )",
  maxFileUploadMsgCSV: "*** Maximum file size should be 5 MB ( TSV )",

  funding: "Funding",
  maturityDate: "Maturity Date",
  vendor: "Vendor",
  lpoNumber: "LPO Number",
  totalAmountPayable: "Total Amount Payable",
  verifOtp: "OTP Verification",
  enterOtp: "Enter OTP sent to ",

  loanReport: "Manage Loan Report",
  loanId: "Loan ID",
  originalLoan: "Original Loan",
  loanBalance: "Loan Balance",
  loanDetail: "Loan Detail",
  outstandingBalance: "Outstanding Balance",
  approvedOn: "Approved on",
  disbursementDate: "Disbursement Date",
  valueDate: "Value Date",
  fundingDate: "Funding Date",
  disbursedAmount: "Disbursed Amount",
  noOfRepayments: "# of Repayments",
  principal: "Principal",
  interest: "Interest",
  total: "Total",
  original: "Original",
  paid: "Paid",
  outstanding: "Outstanding",
  overdue: "Overdue",
  fees: "Fees",
  principalAmount: "Principal Amount",

  // RQ V2
  intentId: "Intent Id",
  manageSupplierIntent: "Manage Supplier Request",
  requestFunding: "Request Funding",
  public: "Public",
  private: "Private",
  selectRequestType: "Select Funding Type",
  newRqFunding: "New Request Funding",
  noteInformation: "Note Information",
  intentPoDetail: "Intent PO Detail",
  intentInvoiceDetail: "Intent Invoice Detail",
  noteYetToConfigMsg: "Note Info is not available.",
  investorLoanAmnt: "Bid Amount",
  investorInterest: "Investor Interest Rate (%)",
  bidInfo: "Bid Information",
  maxBand: "Max Amount",
  manageAdHoc: "Manage Ad-Hoc",
  configId: "Config Id",
  configName: "Config Name",
  configValue: "Config Value",
  editAdHoc: "Edit Ad-Hoc",
  adHoc: "Ad-Hoc Information",
  downloadEntityMsg: "No data available to download",
  reportDownload: "Report Download",
  publicBiding: "Public Listing",
  privateBiding: "Pivate Listing",
  manageBid: "Manage Bid",
  bidByPo: "Bid Detail",
  bidCount: "Bid Count",
  funderName: "Funder Name",
  funderRate: "Funder Rate (%)",
  funderAmnt: "Funder Amount",
  poBidListing: "PO Bid Listing",
  invoiceBidListing: "Invoice Bid Listing",
  poBid: "PO Bid",
  invoiceBid: "Invoice Bid",
  bidingActivity: "Bidding Activity",
  selectSector: "Select Sector",
  bidding: "Bidding",
  depositoryId: "Depository Id",
  depositoryName: "Depository Name",
  depoName: "Dep.",
  confirmBid: "Confirm Bid",
  tenor: "Tenor(Month)",
  activePo: "Active PO",
  acceptedDate: "Accepted Date",
  submissionDate: "Submission Date",
  invDueDate: "Due Date",
  invDate: "Invoice Date",
  planedPymtDate: "Planed Payment Date",
  noDataMsg: "No data available.",
  selectType: "Select Request Type",
  rejectAll: "Reject All",
  invRefId: "Invoice Ref ID",
  invAmount: "Amount",
  fundingType: "Funding Type",
  noDataAvailableMsg: "No data available.",
  poTitle: "PO",
  invoiceTitle: "Invoice",
  availableItem: "Available",
  quotedItem: "Quoted",
  fundedItem: "Funded",
  rejectedItem: "Rejected",
  awaitningItem: "Awaiting",
  manageFee: "Manage Fee",
  calculationInfo: "Fee Infomation",
  displayFeeBtn: "View Fee",
  slabInfo: "Tiered Fees Information",
  modalDoneBtn: "Done",
  modalSaveBtn: "Save",
  otherFee1: "Other Fee 1",
  otherFee2: "Other Fee 2",
  amountPayable1: "Amount Payable 1",
  amountPayable2: "Amount Payable 2",
  amountReceivable: "Amount Receivable",
  fundingFeeInfo: "Funding Fee Information",
  totalSettlementAmount: "Total Settlement Amount",
  transactionFee: "Transaction Fee",
  difference: "Difference",
  calc: "Calc.",
  bidAmount: "Bid Amount",
  cumulativeRate: "Cumulative Rate",
  systemSlogan: `${OWNER} DealRoom`,
  settlementReport: "Settlement Report",
  bidReport: "Bid Report",
  entityReport: "Entity Report",
  settlementReport: "Settlement Report",
  fundedAmount: "Funded Amount",
  accept: "Accept",
  processing: "Processing..",
  smthWentWrong:
    "We were unable to obtain timely feedback on your request. Please retry.",
  // 'If you still haven\'t received an answer. Please double-check your network or get in touch with our system administrator.',

  // RRA PROJECT
  slogan: "Rwanda Revenue Authority",
  fldReq: "Field is required.",
  dashboard: "Dashboard",
  customer: "Customer",
  pendingMaker: "Pending Approval",
  pendingChecker: "Pending Authorization",
  totalApproved: "Total Approved",
  totalRejected: "Total Rejected",
  totalDeregistered: "Total Deregistered",
  pendingDeregistration: "Pending deregistration",
  account: "Account",
  userType: "User Type",
  role: "Role",
  personal: "Personal",
  isRequired: " is required.",
  newUser: "New User",
  editUser: "Edit User",
  userSetup: "User Setup",
  editUserSetup: "Edit User Setup",
  secGeneral: "general", //always in small caps
  secBank: "bank", //always in small caps
  secAdditional: "additional", //always in small caps
  secAmount: "amount", //always in small caps
  secContact: "contact", //always in small caps
  manageUser: "Manage User",
  userDetail: "User Detail",
  userId: "User ID",
  userName: "Username",
  emailAddress: "Email Address",
  userPassword: "Password",
  userFullName: "Full Name",
  userPhone: "Phone Number",
  manageAccount: "Manage Account",
  accountDetail: "Account Detail",
  tin: "Tax ID",
  accountInformation: "Account Information",
  idNumber: "ID Number",
  fldWithAsteRq: "Fields with * are required.",
  idType: "ID Type",
  recentAccountActivities:"Recent Account Activities",
  confirmApprovalMsg: "The customer details has been checked and verified to be correct on the core banking system",
  checkboxMsg:"Please check the box below to confirm the validity of customer information.",
  profileId:"Profile Id",
  customerInfo:"Customer Information",

  rejectReason: "Rejection reason/comment",
  userName: "Username",
  userName: "Username",
  companyAccountName: "Company Account Name",
  companyAccountNo: "Company Account Number",
  companyBranchCode: "Company Branch",
  companyTin: "Company Tax ID",
  disable: "Disable",
  enable: "Enable"
};

export default appLabel;
