import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import RecordStatus from "../ui-setup/RecordStatus";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import {
  InputAmountFacility,
  InputTextFacility,
  Col12BlockFacility,
  NoDataFac,
  InputTextFac
} from "../layouts/Facility";

const { toolTip } = appStatic;

export const GlobalDetailScreen = (props) => {
  const { arrayData, sectionParam,  fieldTypeParam, topTitleParam } =
    props;

  const dispatch = useDispatch();
  const sectionValue = sectionParam ? sectionParam : appLabel.secGeneral
  const fieldTypeValue = fieldTypeParam ? fieldTypeParam : appLabel.secAmount
  const topTitleValue = topTitleParam //? topTitleParam : appLabel.noTitleFoundMsg

  useEffect(() => {
  }, []);
  return (
    <Col12BlockFacility topTitle={topTitleValue}>
      {/* add hr line if title is not provided */}
      {!topTitleValue && (<hr/>)} 

      {arrayData.map((item, index) => (
        <div key={index}>
          {item.section === sectionValue &&
            (item.fldType === fieldTypeValue ? (
              <InputAmountFacility
                inputLabel={item.name}
                inputValue={item.ggHtmlAttributes.defaultValue}
              />
            ) : (
              <InputTextFac
                inputLabel={item.name}
                // inputValue={item.value}
                ggHtmlAttributes={item.ggHtmlAttributes}
              />
            ))}
        </div>
      ))}
      <NoDataFac arrList={arrayData} />
    </Col12BlockFacility>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  // recordData: state.staticData.recordStatus
  recordData: state.recordStatus,
  menuData: state.staticData.menuData,
  authBuffer: state.auth.authBuffer,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDetailScreen);
