import React, { useState, useEffect } from "react";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import appStatic from "../../config/appStaticData";
import RightSide from "../ui-setup/RightSide";
import appLabel from "../../config/appLabel";
import { ColBlockFac, HandleReqFldMsg, BgImgFac, BackBtnFac } from "../layouts/Facility";

const ChangePassword = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { auth, userData, history } = props;
  const { profileId, session:sessionId, companyId } = userData;
  const [currentPwd, setCurrentPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [currentPwdShown, setCurrentPwdShown] = useState(false);
  const [newPwdShown, setNewPwdShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);

  useEffect(() => {}, []);


  const togglePassword = (key) => {
    switch (key.toLowerCase()) {
      case "current":
        return setCurrentPwdShown(!currentPwdShown);
      case "new":
        return setNewPwdShown(!newPwdShown);
      case "confirm":
        return setConfirmPwdShown(!confirmPwdShown);
    }
  };

  const formSubmit = (formValues) => {

    if (formValues.userNewPassword !== formValues.confirm) {
        BOM.AlertMsg(cnt.WARNING, "The new and confirm passwords do not match.");
      return;
    }
    delete formValues.confirm;  

    let body = {
      profileId,
      userId:profileId,
      sessionId,
      ...formValues,
    };

    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.FetchReqAction(body, api.ChangePassword, (err, res) => {
        if (err) {
          BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        } else {
          BOM.AlertMsg(
            cnt.SUCCESS,
            res.message
          );
          history.push("/dashboard");
        }
      });
  };

  return (
    <Main {...props} onSaveChange={handleSubmit(formSubmit)}>
      <form
        method="POST"
        autoComplete="off"
      >
        <ContentTop pageTitle={appLabel.changePwd} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-content content-card-v2">
                  <ColBlockFac>
                  <BackBtnFac urlParam={"dashboard"} labelParam={appLabel.cancelBtn} icon={"times"} btnClass={"of-btn-danger"} />
                  </ColBlockFac>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="al-subtitle">
                          {appLabel.currentPwd}
                        </label>
                        <input
                          type={currentPwdShown ? "text" : "password"}
                          className="form-control m-b input-sm"
                          {...register("userCurrentPassword", {
                            required: HandleReqFldMsg(appLabel.currentPwd),
                            minLength: {
                              value: 8,
                              message: "Minimum password length is 8 characters",
                            },
                            maxLength: {
                              value: 12,
                              message: "Maximum password length is 12 characters",
                            },
                            pattern: {
                              value: BOM.RegExValidation('password'),
                              message: "At least one Uppercase, Lowercase, Digit, Special Character"
                            },
                          })}
                          placeholder="************"
                        />
                        <span
                          className="p-change-viewer"
                          onClick={() => togglePassword("current")}
                        >
                          <i
                            className={
                              currentPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          />
                        </span>
                        <span className="asterisks">
                          {errors?.userCurrentPassword?.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <label className="al-subtitle">{appLabel.newPwd}</label>
                        <input
                          type={newPwdShown ? "text" : "password"}
                          className="form-control m-b input-sm"
                          {...register("userNewPassword", {
                            required: HandleReqFldMsg(appLabel.newPwd),
                            minLength: {
                              value: 8,
                              message: "Minimum password length is 8 characters",
                            },
                            maxLength: {
                              value: 12,
                              message: "Maximum password length is 12 characters",
                            },
                            pattern: {
                              value: BOM.RegExValidation('password'),
                              message: "At least one Uppercase, Lowercase, Digit, Special Character"
                            },
                          })}
                          placeholder="************"
                        />
                        <span
                          className="p-change-viewer"
                          onClick={() => togglePassword("new")}
                        >
                          <i
                            className={
                              newPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          />
                        </span>
                        <span className="asterisks">
                          {errors?.userNewPassword?.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <label className="al-subtitle">
                          {appLabel.confirmPwd}
                        </label>
                        <input
                          type={confirmPwdShown ? "text" : "password"}
                          className="form-control m-b input-sm"
                          placeholder="************"
                          {...register("confirm", {
                            required: HandleReqFldMsg(appLabel.confirmPwd),
                            minLength: {
                              value: 8,
                              message: "Minimum password length is 8 characters",
                            },
                            maxLength: {
                              value: 12,
                              message: "Maximum password length is 12 characters",
                            },
                            pattern: {
                              value: BOM.RegExValidation('password'),
                              message: "At least one Uppercase, Lowercase, Digit, Special Character"
                            },
                           })}
                        />
                        <span
                          className="p-change-viewer"
                          onClick={() => togglePassword("confirm")}
                        >
                          <i
                            className={
                              confirmPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          />
                        </span>
                        <span className="asterisks">
                          {errors?.confirm?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="col-md-6 col-md-offset-4"
                        style={{ marginTop: "20%" }}
                      >
                        <i className="fa fa-lock text-navy fa-5x" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
