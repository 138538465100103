import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { BtnRightTxtIconFac } from "../layouts/Facility";
import { connect } from "react-redux";

import history from "../../history";
function RightSideBar(props) {
  const {
    rightAction,
    rightBtn,
    onCreate,
    onSave,
    onViewDetail,
    onEdit,
    onDelete,
    onApprove,
    onReject,
    onSaveChange,
  } = props;
  const globalVar = window.globalData;

  useEffect(() => {
  }, []);
  const iconList = [
    // {
    //   name: "Search",
    //   icon: "search",
    //   callbackFn:
    // },
    {
      name: "Create",
      icon: "plus",
      callbackFn: onCreate,
      btnStyle: `${rightBtn.addBtn}`,
    },
    {
      name: "Save",
      icon: "save",
      callbackFn: onSaveChange,
      btnStyle: `${rightBtn.saveBtn}`,
    },
    {
      name: "View",
      icon: "folder-open-o",
      callbackFn: onViewDetail,
      btnStyle: `${rightBtn.detailBtn}`,
    },
    {
      name: "Edit",
      icon: "edit",
      callbackFn: onEdit,
      btnStyle: `${rightBtn.editBtn}`,
    },
    {
      name: "Delete",
      icon: "trash",
      callbackFn: onDelete,
      btnStyle: `${rightBtn.deleteBtn}`,
    },
    {
      name: "Approve",
      icon: "check-circle",
      callbackFn: onApprove,
      btnStyle: `${rightBtn.approveBtn}`,
    },
    {
      name: "Reject",
      icon: "times-circle",
      callbackFn: onReject,
      btnStyle: `${rightBtn.rejectBtn}`,
    },
  ];
  return (
  <div id="right-sidebar">
      {iconList.map((item, index) => (
        <div className="right-btn-wrap" key={index}>
          <BtnRightTxtIconFac
            isDisabled={item.btnStyle.includes('disabled') ? true:false}
            btnFuction={item.callbackFn}
            btnClass={`btn ${item.btnStyle} btn-xs`}
            btnIcon={item.icon}
            title={item.name}
            btnName={item.name}
          />
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    rightBtn: state.rightSideBtn,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RightSideBar);
