import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { rightAction } from "../../services/redux/actions/staticData-act";
import { ButtonRightFacility, BtnSubmitRightFac } from "../layouts/Facility";

const RightSide = (props) => {
  const {
    rightAction,
    rightBtn,
    onCreate,
    onSave,
    onView,
    onEdit,
    onDelete,
    onApprove,
    onReject,
    onSaveChange,
  } = props;
  const globalVar = window.globalData;

  // const [addBtn, setAddBtn] = useState("btn-default disabled");
  // const [saveBtn, setSaveBtn] = useState("btn-default disabled");
  // const [detailBtn, setDetailBtn] = useState("btn-default disabled");
  // const [editBtn, setEditBtn] = useState("btn-default disabled");
  // const [deleteBtn, setDeleteBtn] = useState("btn-default disabled");
  // const [approveBtn, setApproveBtn] = useState("btn-default disabled");
  // const [rejectBtn, setRejectBtn] = useState("btn-default disabled");

  useEffect(() => {
    // console.log(onSaveChange);
    //   console.log(window.location.host);
    // console.log("this is right side action: ", props);
  }, []);

  return (
    <div className="sidebard-panel">
      <div
        className="row text-center"
        style={{ marginTop: "40px", marginBottom: "20px" }}
      >
        <Link
          to={`/${globalVar.addNewLink}`}
          id="addBtn"
          className={`btn ${rightBtn.addBtn} btn-circle btn-lg btn-outline`}
          title="Add New"
        >
          <i className="fa fa-plus"></i>
        </Link>
      </div>
      {/* SAVE BTN LOGIC  */}
      {onSaveChange ? (
        <ButtonRightFacility
          btnFuction={onSaveChange}
          btnClass={rightBtn.saveBtn}
          btnIcon={"fa-save"}
          title={"Save"}
        />
      ) : (
        <div className="row text-center" style={{ marginBottom: "20px" }}>
          <button
            type="submit"
            id="saveBtn"
            className={`btn ${rightBtn.saveBtn} btn-circle btn-lg btn-outline`}
            title="Save"
          >
            <i className="fa fa-save"></i>
          </button>
        </div>
      )}
      {/* END SAVE BTN LOGIC  */}

      <div className="row text-center" style={{ marginBottom: "20px" }}>
        <Link
          to={{
            pathname: globalVar.viewLink.path,
            state: {
              ggParam: globalVar.viewLink.obj,
            },
          }}
          className={`btn ${rightBtn.detailBtn} btn-circle btn-lg btn-outline`}
          title="Get details"
        >
          <i className="fa fa-folder-open-o"></i>
        </Link>
      </div>

      <div className="row text-center" style={{ marginBottom: "20px" }}>
        <Link
          to={{
            pathname: globalVar.editLink.path,
            state: {
              ggParam: globalVar.editLink.obj,
            },
          }}
          className={`btn ${rightBtn.editBtn} btn-circle btn-lg btn-outline`}
          title="Edit"
        >
          <i className="fa fa-edit"></i>
        </Link>
      </div>

      <ButtonRightFacility
        btnFuction={onDelete}
        btnClass={rightBtn.deleteBtn}
        btnIcon={"fa-trash"}
        title={"Delete"}
      />
      <ButtonRightFacility
        btnFuction={onApprove}
        btnClass={rightBtn.approveBtn}
        btnIcon={"fa-thumbs-up"}
        title={"Approve"}
      />
      <ButtonRightFacility
        btnFuction={onReject}
        btnClass={rightBtn.rejectBtn}
        btnIcon={"fa-thumbs-down"}
        title={"Reject"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    rightBtn: state.rightSideBtn,
  };
};

const mapDispatchToProps = { rightAction };

export default connect(mapStateToProps, mapDispatchToProps)(RightSide);
