import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import {
  TableFacility,
  AmntFormatFac,
  HandleSkeletonTbleFac,
  ColBlockFac,
} from "../layouts/Facility";
import history from "../../history";

const ManageAccount = (props) => {
  const { userData, menuData, location } = props;
  const { profileId, session: sessionId, userRoleId } = userData;
  const { state, pathname } = location;

  const { tableData } = appStatic;

  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [usersData, setUsersData] = useState([]);
  const [titleRight, setTitleRight] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tiles, setTiles] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const tempTile = [
    {
      title: appLabel.pendingMaker,
      icon: "fa fa-clock-o",
      criteria: "M",
      apiUrl: api.GetCustomerList,
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.pendingChecker,
      icon: "fa fa-clock-o",
      criteria: "C",
      apiUrl: api.GetCustomerList,
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.totalApproved,
      icon: "check",
      criteria: "A",
      apiUrl: api.GetCustomerList,
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.totalRejected,
      icon: "times",
      criteria: "R",
      apiUrl: api.GetCustomerList,
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.pendingDeregistration,
      icon: "fa fa-clock-o",
      criteria: "P",
      apiUrl: api.GetCustomerList,
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.totalDeregistered,
      icon: "fa fa-times",
      criteria: "D",
      apiUrl: api.GetCustomerList,
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ];

  useEffect(() => {
    handleSwitchCondition();
  }, []);

  function getListByCriteria(params) {
    // console.log(params);
    const { criteria, apiUrl } = params;
    setUsersData([]);
    setTitleRight(params.title || params.name);
    setIcon(params.icon);

    let body = {
      profileId,
      criteria,
      recordFrom: "0",
      recordTo: "1000",
      sessionId,
      apiUrl,
    };
    // console.log(body);
    // return;
    setIsLoading(true);

    /** FETCH ROLE */
    BOM.FetchReqAction(body, apiUrl, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        setIsLoading(false);
      } else {
        // console.log(res);
        setUsersData(res.data);
        BOM.DatatableUsage();
        setIsLoading(false);
      }
    });
  }

  const onSelectItem = (obj) => {
    
    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }


    setSelectedItem(obj);
  };

  const handleSwitchCondition = () => {
    let temp = [];

    switch (userRoleId) {
      case "RL0000000000010": // maker
        temp.push(tempTile[0]);
        temp.push(tempTile[2]);
        temp.push(tempTile[3]);
        temp.push(tempTile[4]);
        temp.push(tempTile[5]);
        break;
      case "RL0000000000011": // checker
        temp.push(tempTile[1]);
        temp.push(tempTile[2]);
        temp.push(tempTile[3]);
        temp.push(tempTile[4]);
        temp.push(tempTile[5]);
        break;
      case "RL0000000000009": // system admin or custodian
        temp = tempTile;
        break;
      default:
        BOM.AlertMsg(
          cnt.WARNING,
          "Unauthorized access to this site is only permitted for administrators."
        );
        history.push("/");
        break;
    }

    setTiles(temp);
    getListByCriteria(state ? state.ggParam : temp[0]);
  };

  const onViewDetail = () => {
    const obj = selectedItem;

    if (Object.keys(obj).length === 0) return BOM.AlertMsg(cnt.WARNING, "Please select an item to open.");


   
    switch (obj.flgStatus.toLowerCase()) {
      case "p":
      case "d":
        history.push("/deregister-account-detail", {
          ggParam: obj,
        });
        break;

      default:
        history.push("/account-detail", {
          ggParam: obj,
        });
        break;
    }
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props} onViewDetail={onViewDetail}>
      <ContentTop pageTitle={appLabel.manageAccount} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-content content-card-v2">
                <ColBlockFac>
                  <div className="ibox-tools">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <span className={`btn __btn-primary btn-rounded btn-sm`}>
                        <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight}{" "}
                        &nbsp;
                        <i className="fa fa-chevron-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      {tiles.map((tile, tileIndex) => {
                        if (tile.title !== titleRight) {
                          return (
                            <li key={tileIndex}>
                              <a onClick={() => tile.callback(tile)}>
                                {" "}
                                {tile.title}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </ColBlockFac>

                <div className="col-lg-12 m-t">
                  <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                    {usersData.length === 0 ? (
                      <div className="alert alert-warning text-center">
                        <a className="alert-link">
                          {appLabel.noDataInTableMsg}
                        </a>
                      </div>
                    ) : (
                      <TableFacility thData={tableData.ListCustomer}>
                        {usersData.map((item, index) => (
                          <tr className="gradeX" key={index}>
                            <td>
                              <label className="myradio-button">
                                <input
                                  type="radio"
                                  name="radio"
                                  onClick={() => {
                                    onSelectItem(item);
                                  }}
                                />
                                <span className="label-visible">
                                  <span className="myfake-radiobutton"></span>
                                </span>
                              </label>
                            </td>
                            <td>{item.userId}</td>
                            <td>{item.userFirstname}</td>
                            <td>{item.userLastname}</td>
                            <td>{item.username}</td>
                          </tr>
                        ))}
                      </TableFacility>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageAccount);
