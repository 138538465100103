import React from "react";
import { Navigate, Route, Switch } from "react-router-dom";

// -------------- PAGES IMPORTS --------------

import RouteGuard from "../components/layouts/RouteGuard";

/** AUTH */
import SignIn from "../components/auth/SignIn";
import ChangePassword from "../components/auth/ChangePassword";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import LockScreen from "../components/auth/LockScreen";

import Dashboard from "../components/dashboard/Dashboard";
import ManageRole from "../components/security/role/ManageRole";
import DetailRole from "../components/security/role/DetailRole";
import ManageUser from "../components/security/user/ManageUser";
import NewUser from "../components/security/user/NewUser";
import DetailUser from "../components/security/user/DetailUser";
import EditUser from "../components/security/user/EditUser";

import ManageAccount from "../components/customer/ManageAccount";
import DetailAccount from "../components/customer/DetailAccount";
import DetailAccountDeregistration from "../components/customer/DetailAccountDeregistration";

// import RoleConfig from "../components/systemAdmin/RoleConfig";

import PageNotFound from "../components/ui-setup/PageNotFound";

export const RoutedContent = () => {
  return (
    <Switch>
      {/* ROOT PATH */}
      <Route path="/" component={SignIn} exact />
      <Route path="/sign-in" component={SignIn} exact />
      <RouteGuard path="/dashboard" component={Dashboard} exact />

      {/* CUSTOMER */}
      <RouteGuard path="/manage-account" component={ManageAccount} exact />
      <RouteGuard path="/account-detail" component={DetailAccount} exact />
      <RouteGuard path="/deregister-account-detail" component={DetailAccountDeregistration} exact />
      
      {/*SECURITY*/}
      <RouteGuard path="/manage-user" component={ManageUser} exact />
      <RouteGuard path="/new-user" component={NewUser} exact />
      <RouteGuard path="/user-detail" component={DetailUser} exact />
      <RouteGuard path="/edit-user" component={EditUser} exact />

      <RouteGuard path="/manage-role" component={ManageRole} exact />
      <RouteGuard path="/role-detail" component={DetailRole} exact />

      {/* AUTH */}
      <Route path="/sign-in" component={SignIn} exact />
      <Route path="/forgot-password" component={ForgotPassword} exact />
      <Route path="/reset-password/:token" component={ResetPassword} exact />
      <Route path="/change-password" component={ChangePassword} exact />
      <Route path="/lockscreen" component={LockScreen} exact /> 

      {/* NOT FOUND */}
      <RouteGuard path="*" component={PageNotFound} />
    </Switch>
  );
};
