import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo/Ecobank_logo.png";
import appLabel from "../../config/appLabel";
import { BOM, RAL } from "../../services/defined.action";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import appStatic from "../../config/appStaticData";
import { HandleSkeletonMenuFac } from "../layouts/Facility";
import history from "../../history";
import LogoSymbol from "../../assets/img/logo/powered_by_logo_.png";

const Sidebar = (props) => {
  const { isSignedIn, userData, menuData, location } = props;
  const { profileId, session, userRoleId } = userData;
  const dispatch = useDispatch();
  const [activo, setActivo] = useState("");
  const [secondLevel, setSecondLevel] = useState(false);
  const [roleMenuData, setRoleMenuData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    
    getRoleMenu(userRoleId);
  }, []);

  function getRoleMenu(roleId) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    let body = {
      profileId,
      roleId,
      sessionId:session,
    };

    BOM.FetchReqAction(body, api.GetRoleMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        // setIsLoading(false);
      } else {
        // console.log(res);
        var roleMenu = res.data ? res.data.functions: [];

        /** ORDER ITM S BY POSITION */
        const myData = []
          .concat(roleMenu)
          .sort((a, b) => (a.POSITION > b.POSITION ? 1 : -1));
        setRoleMenuData([...myData]);

        $("#side-menu").metisMenu();
         //UPDATE USER STORED MENU
         dispatch({
          type: cnt.MENU_DATA,
          payload: roleMenu,
        });

        //HANDLE CREATE RIGHT
        BOM.HandleCreateRight(appStatic.pathData, roleMenu, location.pathname);
        setIsLoading(false)

      }
    });
  }

  const activeRoute = (routeName) => {
    // console.log(routeName);
    if (props.location.pathname.toLowerCase() === routeName.toLowerCase()) {
      return "active";
    }
    return "";
  };

  const onRedirectRoute = (stateParam) => {
    history.push(stateParam);
  };
  
  const renderMenu = roleMenuData.map((menu, index) => {
    return (
      <li className={activeRoute(menu.URL)} key={index}>
        {menu.SUB_MENU.length === 0 ? (
          <Link to={menu.URL}>
            <i className={`fa fa-${menu.ICON} text-light-green of-icon-size`} ></i>{" "}
            <span className="nav-label">{menu.DESCRIPTION}</span>
          </Link>
        ) : (
          <React.Fragment>
            <a>
              <i className={`fa fa-${menu.ICON} text-light-green of-icon-size`} ></i>{" "}
              <span className="nav-label">{menu.DESCRIPTION}</span>
              <span className="fa arrow" />
            </a>
            <ul className="nav nav-second-level collapse">
              {menu.SUB_MENU.map((submenu, subIndex) => {
                return (
                  <li className={activeRoute(submenu.URL)} key={subIndex}>
                    {submenu.DESCRIPTION === "xxxxxx" ? (
                      <a href={submenu.URL} target={"_blank"}>
                        {submenu.DESCRIPTION}
                      </a>
                    ) : (
                      <Link to={submenu.URL}>{submenu.DESCRIPTION}</Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </React.Fragment>
        )}
      </li>
    );
  });

  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li
            className="nav-header_old"
            style={{ marginTop: "20%", marginBottom: "20%" }}
          >
            <div
              className="dropdown profile-element"
            >
              <span>
                <img
                  alt="image"
                  style={{ width: "130px", marginLeft: "40px", borderRadius:'15px' }}
                  src={Logo}
                />
              </span>
            </div>
            <div className="logo-element">ECB</div>
          </li>
          {isLoading && <HandleSkeletonMenuFac />}
          {renderMenu}
        </ul>
      </div>
      {/* <div className="profile-element" style={{ marginTop: "200px", textAlign: "center", color: "azure" }}>
       
        <a
          href="https://openfactorgroup.com/"
          target="_blank"
          style={{ fontWeight: "900", fontSize: "12px", color: "#D4AF37" }}
        >
          <img className="logo-symbol" width={150} src={LogoSymbol} />
        </a>
      </div> */}
    </nav>
  );
};


const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
