/**
 * @author Gedeon Gibango
 * @date June 2023
 */

import appConfig from "../config/appConfig";

const IsProductionUrl = (key) => {
  switch (key) {
    case true:
      return appConfig.baseURL.prod;
    case false:
      return appConfig.baseURL.dev;
  }
};


const BASE_URL = (apiParam) => {
  return `${IsProductionUrl(appConfig.isProduction)}/${apiParam}`;
};

/** APIS CONSTANT */
export const apis = {
  /** AUTH */
  Authentication: BASE_URL("admin/authentication"),
  ChangePassword: BASE_URL("changePassword"),
  ForgotPassword: BASE_URL("forgotPassword"),
  ResetPassword:  BASE_URL("resetPassword"),
  CreateUser:     BASE_URL("createUser"),
  EditUser:     BASE_URL("editUser"),

  /** SECURITY */
  //Role
  GetRole: BASE_URL("getRoleList"),
  GetRoleDetail: BASE_URL("getRoleDetail"),
  GetRoleMenu: BASE_URL("getRoleMenu"),

  //User
  GetUser: BASE_URL("admin/getAllAdmin"),
  GetUserDetails: BASE_URL("getUserDetails"),
  UpdateUserStatus: BASE_URL("updateUserStatus"),
  UpdateAdminUser: BASE_URL("admin/updateAdminUser"),

  // OTHERs
  GetDashboardCounts: BASE_URL("getDashboardCounts"),
  ApproveRejectCustomer: BASE_URL("customer/approveRejectCustomer"),
  GetCustomerList: BASE_URL("customer/getCustomerList"),

  // not in use anymore
  CustomersPendingMakerApproval: BASE_URL("customer/customersPendingMakerApproval"),
  CustomersPendingCheckerApproval: BASE_URL("customer/customersPendingCheckerApproval"),
};
