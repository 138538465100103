/**
 * @author Gedeon Gibango
 * @date June 2023
 */

import React, { useState, useEffect } from "react";
import useGeolocation from "react-hook-geolocation";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { cnt } from "../../services/constant.action";

import {
  SignInAction,
  SignOutAction,
} from "../../services/redux/actions/auth-act";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import history from "../../history";
import { SubmitBtnLoad, BgImgFac, HandleReqFldMsg, OutlineFldErr } from "../layouts/Facility";

import Logo from "../../assets/img/logo/eco-logo-white-svg.svg";
import Bg from "../../assets/img/logo/banking-bg.jpg";

const SignIn = (props) => {
  /** INITIALIZE VARIABLES */
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { SignOutAction } = props;

  useEffect(() => {
    SignOutAction();
  }, []);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onSignIn = (formData) => {
    setIsLoading(true);

    const body = {
      ...formData,
      sourceIp: "197.184.164.52", //ip.IPv4,
      browser: BOM.GetBrowserName(),
      host: window.location.host,
      osName: "windows",
      longitude: "-25.757", //ip.longitude + "",
      latitude: "28.1443", //ip.latitude + "",
      channel: "self service",
    };
    
    BOM.SessionLessReqAction(body, api.Authentication, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        setIsLoading(false);
      } else {
        BOM.NotifyAlert(cnt.SUCCESS, "Welcome back " + res.data.profileFirstName)
        dispatch({
          type: cnt.SIGN_IN,
          payload: res.data,
        });
        history.push("/dashboard");
      }
    });
  };
  async function getRoleMenu(user) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId: user.profileId,
      roleId: user.userRoleId,
      session: user.session,
    };

    BOM.FetchReqAction(body, api.GetRoleMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.roleDataResponseModel.functions;
        dispatch({
          type: cnt.MENU_DATA,
          payload: objList,
        });
        // $("#side-menu").metisMenu();
      }
    });
  }

  return (
    <div style={{ overflowX: "hidden", overflowY: "auto" }}>
      <BgImgFac img={Bg} />
      <div className="centeredContent">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="middle-box loginscreen animated fadeInDown login-card"
                style={{ marginBottom: "0%" }}
              >
                <div className="text-center">
                  <img
                    alt="image"
                    style={{
                      width: "150px",
                      marginTop: "20px",
                      marginBottom: "70px",
                    }}
                    src={Logo}
                  />
                </div>

                <form onSubmit={handleSubmit(onSignIn)} autoComplete="off">
                  <div className="form-group">
                    <label className="text-left text-white">
                      {appLabel.username}{" "}
                      <small style={{ fontWeight: "normal" }}>
                        {appLabel.userNameExemple}
                      </small>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${OutlineFldErr(errors.username)}`}
                      placeholder={appLabel.username}
                      {...register("username", {
                        required: HandleReqFldMsg(appLabel.username),
                        pattern: {
                          value: BOM.RegExValidation('email'),
                          message: "Email must be valid.",
                        },
                      })}
                    />
                    <mark
                      className={`${
                        errors?.username?.message ? "asterisks" : "hide"
                      }`}
                    >
                      {errors?.username?.message}
                    </mark>
                  </div>
                  <div className="form-group">
                    <label className="text-left text-white">
                      {appLabel.password}
                    </label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className={`form-control ${OutlineFldErr(errors.userPassword)}`}
                      placeholder={appLabel.password}
                      {...register("userPassword", {
                        required: HandleReqFldMsg(appLabel.password),
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8 characters",
                        },
                        maxLength: {
                          value: 12,
                          message: "Maximum length is 12 characters",
                        },
                        pattern: {
                          value: BOM.RegExValidation('password'),
                          message:
                            "At least one Uppercase, Lowercase, Digit, Special Character",
                        },
                      })}
                    />
                    <span className="p-viewer" onClick={() => togglePassword()}>
                      <i
                        className={
                          passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </span>
                    <mark
                      className={`${
                        errors?.userPassword?.message ? "asterisks" : "hide"
                      }`}
                    >
                      {errors?.userPassword?.message}
                    </mark>
                  </div>
                  <div className="form-group text-center">
                    <SubmitBtnLoad
                      isLoading={isLoading}
                      btnName={appLabel.btnLogin}
                      isFullWidth={true}
                      icon={"fa fa-sign-in"}
                    />
                  </div>
                  <div className="form-group text-center ">
                    <Link
                      to="/forgot-password"
                      style={{ fontWeight: "bold", color: "gray" }}
                    >
                      <small className="text-white">
                        {appLabel.forgotpass} ?
                      </small>
                    </Link>
                  </div>
                  {/* <div className="form-group text-center">
                    <label className="text-left">
                      <small>{appLabel.newTo}</small>
                    </label>
                    &nbsp;
                    <Link
                      to="/registration"
                      style={{ fontWeight: "bold", color: "rgb(204, 153, 61)" }}
                    >
                      <small>{appLabel.createAccount}</small>
                    </Link>
                  </div> */}
                </form>
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { SignInAction, SignOutAction })(SignIn);
