// import _ from "lodash";
import { cnt } from "../../constant.action";
import { BOM } from "../../defined.action";

const INITIAL_STATE = {
  success_response: {},
  failed_response: {},
  verifyOrderId: "",
  currentMenu:{},
  menuData: [],
};
export const staticDataRed = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case cnt.RESPONSE_SUCCESS_DATA:
      return {
        ...state,
        success_response: payload,
      };
    case cnt.RESPONSE_FAILED_DATA:
      return {
        ...state,
        failed_response: payload,
      };
    case cnt.VERIFY_ORDER_ID:
      return {
        ...state,
        verifyOrderId: payload,
      };
    case cnt.MENU_DATA:
      return {
        ...state,
        menuData: payload,
      };
      case cnt.CURRENT_MENU:
      return {
        ...state,
        currentMenu: payload,
      };
    case cnt.CLEAR_STATIC_DATA:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

const RIGHT_INITIAL_STATE = {
  addBtn: "right-button  disabled",
  saveBtn: "right-button  disabled",
  detailBtn: "right-button  disabled",
  editBtn: "right-button  disabled",
  deleteBtn: "right-button  disabled",
  approveBtn: "right-button  disabled",
  rejectBtn: "right-button  disabled",
};
export const rightSideBtnRed = (state = RIGHT_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case cnt.RIGHT_ADD_BTN:
      return { ...state, addBtn: "right-button-active" };
    case cnt.RIGHT_SAVE_BTN:
      return { ...state, saveBtn: "right-button-active" };
    case cnt.RIGHT_DETAIL_BTN:
      return { ...state, detailBtn: "right-button-active" };
    case cnt.RIGHT_EDIT_BTN:
      return { ...state, editBtn: "right-button-active" };
    case cnt.RIGHT_DELETE_BTN:
      return { ...state, deleteBtn: "right-button-active" };
    case cnt.RIGHT_APPROVE_BTN:
      return { ...state, approveBtn: "right-button-active" };
    case cnt.RIGHT_REJECT_BTN:
      return { ...state, rejectBtn: "right-button-active" };
    case cnt.CLEAR_RIGHT_BTN:
      return { ...RIGHT_INITIAL_STATE };
    default:
      return state;
  }
};

const INITIAL_RECORD_STATE = {
  FLG_STATUS: "***",
  MAKER_ID: "********",
  MAKER_DATE: "********",
  CHECKER_ID: "********",
  CHECKER_DATE: "********",
};
export const recordStatus = (state = INITIAL_RECORD_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case cnt.RECORD_STATUS:
      return {
        ...state,
        FLG_STATUS: payload.flgStatus,
        MAKER_ID: payload.makerId,
        MAKER_DATE: payload.makerDate,
        CHECKER_ID: payload.checkerId, 
        CHECKER_DATE: payload.checkerDate,
      };
    case cnt.CLEAR_STATIC_DATA:
      return {
        ...INITIAL_RECORD_STATE,
      };

    default:
      return state;
  }
};
