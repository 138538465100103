import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import {
  Tooltips,
  ColBlockFac,
  NoDataFac,
  BackBtnFac,
  
} from "../../layouts/Facility";
import GlobalDetailScreen from "../../layouts/GlobalDetailScreen";

export const DetailUser = (props) => {
  const { location, userData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session } = userData;
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState({});
  const [inputFldData, setInputFldData] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    getDetailById(ggParam);
  }, []);

  const getDetailById = (params) => {
    let body = {
      profileId,
      userId: params.userId,
      sessionId: session,
    };

    BOM.FetchReqAction(body, api.GetUserDetails, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
      } else {
        const objDetail = res.data;
        setUserDetail(objDetail);
        setIsEnabled(
          objDetail?.flgStatus?.toLowerCase() === "a" ? true : false
        );
        setInputFldData([
          {
            name: appLabel.userId,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: objDetail.userId,
            },
          },
          {
            name: appLabel.username,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: objDetail.username,
            },
          },
          {
            name: appLabel.firstName,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: objDetail.userFirstname,
            },
          },
          {
            name: appLabel.lastName,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: objDetail.userLastName,
            },
          },
          
          {
            name: appLabel.roleName,
            fldType: "text",
            section: "general",
            ggHtmlAttributes: {
              type: "text",
              className: "form-control",
              disabled: true,
              defaultValue: objDetail.userRoleName,
            },
          },
        ]);
      }
    });
  };

  const onEnableDisable = (userDetail) => {
    //a:approve
    //b:approve

    let status = "";
    let popupLabel = "";

    switch (userDetail?.flgStatus?.toLowerCase()) {
      case "a":
        status = "b";
        popupLabel = "Disable";
        break;
      case "d":
      case "b":
        status = "a";
        popupLabel = "Enable";
        break;
    }

    let body = {
      profileId,
      userId:userDetail.userId,
      status,
      sessionId: session,
    };

    // console.log(body);return;

    BOM.GlobalApproveRejectAction(
      body,
      api.UpdateUserStatus,
      "manage-user",
      ` ${userDetail.userFirstname} ${userDetail.userLastName}`,
      popupLabel
    );
  };

  return (
    <Main {...props}>
      <form autoComplete="off">
        <ContentTop pageTitle={appLabel.userDetail} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-content content-card-v2">
                  <ColBlockFac>
                    <BackBtnFac />
                    <a
                      onClick={() => {
                        onEnableDisable(userDetail);
                      }}
                      className={`btn  btn-rounded btn-md m-l of-btn-${isEnabled ? "danger":"success"}`}
                    >
                      <i className={`fa fa-${isEnabled ? "times-circle" : "check-circle"}`} /> &nbsp;
                      {isEnabled ? appLabel.disable : appLabel.enable}
                    </a>
                  </ColBlockFac>
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="text-center">
                        <img
                          src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                          className="avatar img-circle img-thumbnail"
                          alt="avatar"
                        />
                      </div>
                      <br />
                    </div>
                    <div className="col-sm-10">
                      <GlobalDetailScreen
                        arrayData={inputFldData}
                        topTitleParam={appLabel.userInformation}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  recordData: state.recordStatus,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailUser);
