import React, { useEffect } from "react";
import appLabel from "../../config/appLabel";
const RecordStatus = (props) => {

  const {FLG_STATUS, MAKER_ID, MAKER_DATE, CHECKER_ID, CHECKER_DATE} = props

  useEffect(()=>{
    // console.log(props);
  },[])

  return (
    <React.Fragment>
        <div style={{marginTop:'20px'}}>
        <div className="col-md-12" style={{marginBottom:'20px'}}>
          
        <div className="ibox-tools">
            <span className="badge badge-primary">
                {appLabel.recordStatus}
            </span>
          </div>
          <div className="hr-line-dashed" style={{ marginTop: '5px' }} />
          </div>
          
          <div className="row">
              <div className="form-group col-md-2">
              <label className="al-subtitle">
                {appLabel.authStatus}
                </label>
                <input
                  type="text"
                  className="form-control pull-left input-sm"
                  value={(FLG_STATUS.trim() === 'A' || FLG_STATUS.trim() === 'D') ? appLabel.authorized: appLabel.unauthorized}
                  readOnly
                />
              </div>
              <div className="form-group col-md-3">
              <label className="al-subtitle">
                  {appLabel.makerId}
                </label>
                <input
                  type="text"
                  className="form-control pull-left input-sm"
                  placeholder={MAKER_ID}
                  readOnly
                />
              </div>
              <div className="form-group col-md-2">
              <label className="al-subtitle">
                  {appLabel.makerDate}
                </label>
                <input
                  type="text"
                  className="form-control pull-left input-sm"
                  placeholder={MAKER_DATE}
                  readOnly
                />
              </div>
              <div className="form-group col-md-3">
              <label className="al-subtitle">
                  {appLabel.checkerId}
                </label>
                <input
                  type="text"
                  className="form-control pull-left input-sm"
                  placeholder={CHECKER_ID}
                  readOnly
                />
              </div>
              <div className="form-group col-md-2">
              <label className="al-subtitle">
                  {appLabel.checkerDate}
                </label>
                <input
                  type="text"
                  className="form-control pull-left input-sm"
                  placeholder={CHECKER_DATE}
                  readOnly
                />
              </div>
          </div>
        </div>
    </React.Fragment>
  );
};

export default RecordStatus;
