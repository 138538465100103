
import appLabel from "./appLabel";
import { apis as api } from "../services/api.action";

import { BOM } from "../services/defined.action";
const appStatic = {
  clientTitle: [
    { id: "1", value: "MS", name: "MS - Master/ Miss" },
    { id: "2", value: "MR", name: "MR - Mister" },
    { id: "3", value: "MRS", name: "MRS" },
    { id: "4", value: "DR", name: "DR - Doctor" },
    { id: "5", value: "PROF", name: "PROF - Professor" },
    { id: "6", value: "ADV", name: "ADV - Advocate" },
    { id: "7", value: "HON", name: "HON - Honorable" },
    { id: "8", value: "AMB", name: "AMB - Ambassador" },
    { id: "9", value: "REV", name: "REV - Reverend" },
    { id: "10", value: "N/A", name: "N/A - Not Applicable" },
  ],
  clientType: [
    { id: "Citizen", name: "Citizen" },
    { id: "Permanent Resident", name: "Permanent Resident" },
    { id: "Emigrant", name: "Emigrant" },
    { id: "Foreign Citizen", name: "Foreign Citizen" },
    { id: "Close Corporation", name: "Close Corporation" },
    { id: "Company", name: "Company" },
    { id: "Foreign Company", name: "Foreign Company" },
    { id: "Other Legal Person", name: "Other Legal Person" },
    { id: "Trust", name: "Trust" },
    { id: "Minor", name: "Minor" },
    { id: "Broker", name: "Broker" },
    { id: "Custodian Bank", name: "Custodian Bank" },
    { id: "Issuer", name: "Issuer" },
    { id: "Registry/Transfer Secretary", name: "Registry/Transfer Secretary" },
    { id: "Settlement Bank", name: "Settlement Bank" },
  ],
  investorType: [
    { id: "Business", name: "Business" },
    // { id: "Individual", name: "Individual" },
  ],

  subActionList: [
    {
      value: "1",
      name: "create",
    },
    {
      value: "1",
      name: "view",
    },
    {
      value: "1",
      name: "edit",
    },
    {
      value: "1",
      name: "approve",
    },
    {
      value: "1",
      name: "reject",
    },
  ],
  menuDataOLD: [
    {
      DESCRIPTION: "Dashboard",
      FUNCTION_ID: "001",
      ICON: "th-large",
      POSITION: "1",
      URL: "/dashboard",
      SUB_MENU: [],
    },
    {
      DESCRIPTION: "Manage Account",
      FUNCTION_ID: "002",
      ICON: "building-o",
      POSITION: "2",
      URL: "",
      SUB_MENU: [
        {
          DESCRIPTION: "New Alias",
          FUNCTION_ID: "0021",
          URL: "/new-alias",
        },
        {
          DESCRIPTION: "Search",
          FUNCTION_ID: "0022",
          URL: "/search",
        },
      ],
    },
    {
      DESCRIPTION: "Reporting",
      FUNCTION_ID: "006",
      ICON: "line-chart",
      POSITION: "6",
      URL: "",
      SUB_MENU: [
        {
          DESCRIPTION: "Reporting",
          FUNCTION_ID: "0061",
          URL: "/manage-report",
        },
        {
          DESCRIPTION: "Audit",
          FUNCTION_ID: "0062",
          URL: "/audit-trail",
        },
      ],
    },
    {
      DESCRIPTION: "System Admin",
      FUNCTION_ID: "007",
      ICON: "lock",
      POSITION: "7",
      URL: "/",
      SUB_MENU: [
        {
          DESCRIPTION: "Manage User",
          FUNCTION_ID: "0071",
          URL: "/manage-user",
        },
        // {
        //   DESCRIPTION: "Role",
        //   FUNCTION_ID: "0072",
        //   URL: "/manage-role",
        // },
        {
          DESCRIPTION: "Role Config",
          FUNCTION_ID: "0074",
          URL: "/role-config",
        },
        // {
        //   DESCRIPTION: "Entity Menu",
        //   FUNCTION_ID: "0074",
        //   URL: "/manage-entity-menu",
        // },
      ],
    },
  ],
  menuData: [
    {
      DESCRIPTION: "Dashboard",
      FUNCTION_ID: "001",
      ICON: "th-large",
      POSITION: "1",
      URL: "/dashboard",
      SUB_MENU: [],
    },
    {
      DESCRIPTION: "Menu 1",
      FUNCTION_ID: "002",
      ICON: "building-o",
      POSITION: "2",
      URL: "",
      SUB_MENU: [
        {
          DESCRIPTION: "Sub Menu 1",
          FUNCTION_ID: "0021",
          URL: "/xxxxx",
        },
        {
          DESCRIPTION: "Sub Menu 2",
          FUNCTION_ID: "0021",
          URL: "/xxxxx",
        },
      ],
    },
    {
      DESCRIPTION: "Menu 2",
      FUNCTION_ID: "002",
      ICON: "building-o",
      POSITION: "2",
      URL: "",
      SUB_MENU: [
        {
          DESCRIPTION: "Sub Menu 1",
          FUNCTION_ID: "0031",
          URL: "/xxxxx",
        },
        {
          DESCRIPTION: "Sub Menu 2",
          FUNCTION_ID: "0031",
          URL: "/xxxxx",
        },
      ],
    },
  ],
  currencyCode: [
    { id: "NGN", name: "Nigerian naira" },
    { id: "GHS", name: "Ghanaian cedi" },
    { id: "GBP", name: "British pound" },
    { id: "EUR", name: "Euro" },
    { id: "USD", name: "US dollar" },
    { id: "ZAR", name: "South African" },
  ],
  chargeType: [
    { id: "Flat", name: "Flat" },
    { id: "Percentage", name: "Percentage" },
  ],
  yesOrNoStatus: [
    { id: "Y", name: "Yes" },
    { id: "N", name: "No" },
  ],
  trueOrFalseStatus: [
    { id: "T", name: "True" },
    { id: "F", name: "False" },
  ],
  otherFee1: [{ name: "Supplier Fee 1", value: "Supplier Fee 1" }],
  HandleFlgStatus: (status) => {
    let statusName = "N/A";
    switch (status.toLowerCase()) {
      case "u":
        return (statusName = "Unauthorized");
      case "m":
        return (statusName = "Pending Approval");
      case "c":
        return (statusName = "Pending Authorization");
      case "a":
        return (statusName = "Approved");
      case "r":
        return (statusName = "Rejected");
      case "p":
        return (statusName = "Pending Deregistration");
      case "d":
        return (statusName = "Deregistered");
      default:
        return statusName;
    }
  },
  HandleFlgStatusIcon: (status) => {
    let statusName = "N/A";
    switch (status.toLowerCase()) {
      case "u":
        return <i className="fa fa-times-circle text-light-green" />;
      case "m":
        return <i className="fa fa-clock-o text-light-green" />;
      case "c":
        return <i className="fa fa-clock-o text-light-green" />;
      case "a":
        return <i className="fa fa-check-circle text-primary" />;
      case "r":
        return <i className="fa fa-times-circle text-danger" />;
      case "p":
        return <i className="fa fa-clock-o text-light-green" />;
      case "d":
        return <i className="fa fa-times text-danger" />;
      default:
        return statusName;
    }
  },
  handleStatusIcon: (key) => {
    switch (key.toLowerCase()) {
      case "open":
        return <i className="fa fa-play fa-rotate-270 text-success" />;
      case "closed":
        return <i className="fa fa fa-play fa-rotate-90 text-danger" />;
      case "a":
        return <i className="fa fa-check-circle text-navy" />;
      case "u":
      case "r":
        return <i className="fa fa-times text-danger" />;
    }
  },
  pathData: [
    { DESCRIPTION: "Manage Account", URL: "/manage-account" },
    { DESCRIPTION: "Reporting", URL: "/manage-report" },
    { DESCRIPTION: "Audit", URL: "/audit-trail" },
    { DESCRIPTION: "User Setup", URL: "/manage-user" },
    { DESCRIPTION: "Manage Role", URL: "/manage-role" },
  ],
  reporting: {},
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "Sptember",
    "October",
    "November",
    "December",
  ],
  toolTip: {},
  notif: {
    // notif1: "If you click Save, you will not be able to receive multiple Funding. To receive multiple Funding, kindly go back and select FMDQP as your Funder."
    notif1:
      "You will not be able to receive multiple Funding. To receive multiple Funding, kindly click cancel and select Public funding at the top.",
    notif2: "Approve or Reject",
  },
  tableData: {
    ListUser: [
      { name: appLabel.userId },
      { name: appLabel.firstName },
      { name: appLabel.lastName },
      { name: appLabel.userName },
      { name: appLabel.roleName },
    ],
    ListCustomer: [
      { name: appLabel.userId },
      { name: appLabel.firstName },
      { name: appLabel.lastName },
      { name: appLabel.userName },
    ],
    ListAccountDashboard: [
      { name: appLabel.userId },
      { name: appLabel.firstName },
      { name: appLabel.lastName },
      { name: appLabel.userName },
      { name: appLabel.status },
    ],
    ListRole: [
      { name: appLabel.roleId },
      { name: appLabel.roleName },
      { name: appLabel.roleDescription },
    ],
  },
  tabData: {
    invShareList: [
      {
        listName: appLabel.investorInfo,
        ref: "#investorInfo",
        icon: "fa fa-tags text-navy",
        isActive: true,
      },
      {
        listName: appLabel.address,
        ref: "#addressInfo",
        icon: "fa fa-home text-navy",
        isActive: false,
      },
    ],
  },
  CRUD: {
    CreateUser: (params) => {
      return [
        {
          name: appLabel.roleName,
          value: "roleId",
          fldType: "select",
          section: "general",
          arrayData: params.roleData,
          required: true,
          ggHtmlAttributes: {
            className: "form-control",
            disabled: false,
          },
        },
        {
          name: appLabel.username,
          value: "userEmail",
          fldType: "text",
          section: "general",
          required: true,
          colClass: "",
          ggHtmlAttributes: {
            type: "text",
            className: "form-control",
            disabled: false,
            placeholder: `Enter ${appLabel.email}`,
          },
          ggValidation: {
            pattern: {
              value: BOM.RegExValidation("email"),
              message: "Email must be valid.",
            },
          },
        },
        {
          name: appLabel.firstName,
          value: "userFirstname",
          fldType: "text",
          section: "general",
          required: true,
          colClass: "",
          ggHtmlAttributes: {
            type: "text",
            className: "form-control",
            disabled: false,
            placeholder: appLabel.firstName,
          },
          ggValidation: {
            // pattern: {
            //   value: /^[a-zA-Z!?@#$%^&*]+$/,
            //   message:`${appLabel.firstName} must be valid.`,
            // },
          },
        },
        {
          name: appLabel.lastName,
          value: "userLastname",
          fldType: "text",
          section: "general",
          required: true,
          colClass: "",
          ggHtmlAttributes: {
            type: "text",
            className: "form-control",
            disabled: false,
            placeholder: appLabel.lastName,
          },
          ggValidation: {
            // pattern: {
            //   value: /^[a-zA-Z!?@#$%^&*]+$/,
            //   message:`${appLabel.lastName} must be valid.`,
            // },
          },
        },
      ];
    },
    EditUser: (params=null) => {
      return [
        {
          name: appLabel.roleName,
          value: "roleId",
          fldType: `${params.isSysAdmin? "xx": "select"}`,
          section: "general",
          arrayData: params.roleData,
          required: true,
          ggHtmlAttributes: {
            className: "form-control",
            disabled: false,
          },
        },
        {
          name: appLabel.firstName,
          value: "firstName",
          fldType: "text",
          section: "general",
          required: true,
          colClass: "",
          ggHtmlAttributes: {
            type: "text",
            className: "form-control",
            disabled: false,
            placeholder: appLabel.firstName,
          },
          ggValidation: {
            // pattern: {
            //   value: /^[a-zA-Z!?@#$%^&*" "]+$/,
            //   message:`${appLabel.firstName} must be valid.`,
            // },
          },
        },
        {
          name: appLabel.lastName,
          value: "lastName",
          fldType: "text",
          section: "general",
          required: true,
          colClass: "",
          ggHtmlAttributes: {
            type: "text",
            className: "form-control",
            disabled: false,
            placeholder: appLabel.lastName,
          },
          ggValidation: {
            // pattern: {
            //   value: /^[a-zA-Z!?@#$%^&*" "]+$/,
            //   message:`${appLabel.lastName} must be valid.`,
            // },
          },
        },
      ];
    },
  },
  DashboardData: (roleParam, objDetail) => {
    let menuJson = {
      makerApproval: {
        name: appLabel.pendingMaker,
        value: objDetail.customerPendingMakerApproval
          ? objDetail.customerPendingMakerApproval
          : 0,
        icon: "fa fa-clock-o",
        color: "navy-bg",
        itemType: appLabel.account,
        criteria: "M",
        recordFrom: "0",
        recordTo: "1000",
        apiUrl: api.GetCustomerList,
        linkUrl: "manage-account",
        dashDisplay: ``,
      },
      checkerApproval: {
        name: appLabel.pendingChecker,
        value: objDetail.customerPendingCheckerApproval
          ? objDetail.customerPendingCheckerApproval
          : 0,
        icon: "fa fa-clock-o",
        color: "navy-bg",
        itemType: appLabel.account,
        criteria: "C",
        recordFrom: "0",
        recordTo: "1000",
        apiUrl: api.GetCustomerList,
        linkUrl: "manage-account",
        dashDisplay: ``,
      },
      totalApproved: {
        name: appLabel.totalApproved,
        value: objDetail.customerApproved ? objDetail.customerApproved : 0,
        icon: "fa fa-check-circle",
        color: "navy-bg",
        itemType: appLabel.account,
        criteria: "A",
        recordFrom: "0",
        recordTo: "1000",
        apiUrl: api.GetCustomerList,
        linkUrl: "manage-account",

        dashDisplay: "",
      },
      totalRejected: {
        name: appLabel.totalRejected,
        value: objDetail.customerRejected ? objDetail.customerRejected : 0,
        icon: "fa fa-times-circle",
        color: "navy-bg",
        itemType: appLabel.account,
        criteria: "R",
        recordFrom: "0",
        recordTo: "1000",
        apiUrl: api.GetCustomerList,
        linkUrl: "manage-account",

        dashDisplay: "",
      },
      deregisterReq: {
        name: appLabel.pendingDeregistration,
        value: objDetail.customerPendingDeRegistration
          ? objDetail.customerPendingDeRegistration
          : 0,
        icon: "fa fa-clock-o",
        color: "navy-bg",
        itemType: appLabel.account,
        criteria: "P",
        recordFrom: "0",
        recordTo: "1000",
        apiUrl: api.GetCustomerList,
        linkUrl: "manage-account",
        dashDisplay: "",
      },
      deregisteredReq: {
        name: appLabel.totalDeregistered,
        value: objDetail.customerDeregistered
          ? objDetail.customerDeregistered
          : 0,
        icon: "fa fa-times",
        color: "navy-bg",
        itemType: appLabel.account,
        criteria: "D",
        recordFrom: "0",
        recordTo: "1000",
        apiUrl: api.GetCustomerList,
        linkUrl: "manage-account",
        dashDisplay: "",
      },
    };

    let returnList = [];
    switch (roleParam) {
      case "RL0000000000011": // checker
        returnList.push(menuJson.checkerApproval);
        returnList.push(menuJson.totalApproved);
        returnList.push(menuJson.totalRejected);
        returnList.push(menuJson.deregisterReq);
        returnList.push(menuJson.deregisteredReq);
        break;
      case "RL0000000000010": // maker
        returnList.push(menuJson.makerApproval);
        returnList.push(menuJson.totalApproved);
        returnList.push(menuJson.totalRejected);
        returnList.push(menuJson.deregisterReq);
        returnList.push(menuJson.deregisteredReq);
        break;
      case "RL0000000000009": // system admin or custodian
        returnList.push(menuJson.makerApproval);
        returnList.push(menuJson.checkerApproval);
        returnList.push(menuJson.totalApproved);
        returnList.push(menuJson.totalRejected);
        returnList.push(menuJson.deregisterReq);
        returnList.push(menuJson.deregisteredReq);
        break;
    }

    return returnList;
  },
};

export default appStatic;
