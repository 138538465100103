

import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import RightSide from "../../ui-setup/RightSide";
import {
  Tooltips,
  ColBlockFac,
  NoDataFac,
  CreateInputFac,
  NavTabFac,
  HandleSkeletonDetailFac,
  TextAreaFac,
  AmountFacility,
  AmntFormatFac,
  AppModalFac,
  TableSimpleFac,
  ErrorHandlerFacIn,
  BackBtnFac,
} from "../../layouts/Facility";
import GlobalDetailScreen from "../../layouts/GlobalDetailScreen";

function EditUser(props) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { location, userData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId } = userData;

  const [isSysAdmin, setIsSysAdmin] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [inputFldData, setInputFldData] = useState([]);

  const { CRUD } = appStatic;
  useEffect(() => {
    getDetailById(ggParam);
  }, []);

  const getDetailById = (params) => {
    let body = {
      profileId,
      userId: params.userId,
      sessionId: session,
    };

    BOM.FetchReqAction(body, api.GetUserDetails, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
      } else {
        const objDetail = res.data;
        setInputFldData([
          {
            name: appLabel.userId,
            value: objDetail.userId,
          },
          {
            name: appLabel.username,
            value: objDetail.username,
          },
          {
            name: appLabel.roleName,
            value: objDetail.userRoleName,
          },
        ]);

        setValue("firstName", objDetail.userFirstname);
        setValue("lastName", objDetail.userLastName);
        setValue("roleId", objDetail.userRoleId);
        const isSysAdmin = objDetail?.userRoleId === "RL0000000000009" ? true : false
        // setIsSysAdmin(
        //   objDetail?.userRoleId === "RL0000000000009" ? true : false
        // );

        getRole(isSysAdmin);

      }
    });
  };
  function getRole(isSysAdmin) {
    let body = {
      profileId,
      criteria: "A",
      roleId: "",
      sessionId: session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetRole, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
      } else {
        const objList = res.data;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            name: element.roleDescription,
            value: element.roleId,
          });
        });

        const params = {
          roleData: temp,
          isSysAdmin
        };

        setInputFields(CRUD.EditUser(params));
      }
    });
  }
  const formSubmit = (formValues) => {
    formValues.userId = ggParam?.userId;
    let body = {
      profileId,
      sessionId: session,
      ...formValues,
    };
    // console.log(body);
    // return;
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.FetchReqAction(body, api.UpdateAdminUser, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        history.push("/manage-user");
      }
    });
  };

  return (
    <Main {...props} onSaveChange={handleSubmit(formSubmit)}>
      <form method="POST" id="NewItemForm" autoComplete="off">
        <ContentTop pageTitle={appLabel.editUser} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-content content-card-v2">
                  <ColBlockFac>
                    <BackBtnFac />
                  </ColBlockFac>
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="text-center">
                        <img
                          src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                          className="avatar img-circle img-thumbnail"
                          alt="avatar"
                        />
                      </div>
                      <br />
                    </div>

                    <div className="col-sm-10">
                      <dl className="dl-horizontal">
                        {inputFldData.map((item, index) => (
                          <span key={index}>
                            <dt>{item.name} :</dt> <dd> {item.value} </dd>
                          </span>
                        ))}
                      </dl>
                      <ColBlockFac topTitle={appLabel.editUserSetup}>
                        {inputFields.map((item, index) => (
                          <div key={index}>
                            {item.section === "general" &&
                              item.fldType === "text" && (
                                <CreateInputFac
                                  inputLabel={item.name}
                                  fieldType={item.fldType}
                                  requiredLabel={item.required}
                                  dynamicClass={item.colClass}
                                >
                                  <input
                                    {...item.ggHtmlAttributes}
                                    {...register(item.value, {
                                      required: item.required
                                        ? BOM.CheckReqFld(item.name)
                                        : false,
                                      ...item.ggValidation,
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.[item.value]?.message}
                                  </span>
                                </CreateInputFac>
                              )}
                            {item.section === "general" &&
                              item.fldType === "select" && (
                                <CreateInputFac
                                  inputLabel={item.name}
                                  fieldType={item.fldType}
                                  requiredLabel={item.required}
                                  dynamicClass={item.colClass}
                                >
                                  <select
                                    {...item.ggHtmlAttributes}
                                    {...register(item.value, {
                                      required: item.required
                                        ? BOM.CheckReqFld(item.name)
                                        : false,
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {item.arrayData.map((subItem, subIndex) => (
                                      <option
                                        value={subItem.id || subItem.value}
                                        key={subIndex}
                                      >
                                        {subItem.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.[item.value]?.message}
                                  </span>
                                </CreateInputFac>
                              )}
                          </div>
                        ))}
                        <NoDataFac arrList={inputFields} />
                      </ColBlockFac>
                    </div>
                    {/*/tab-content*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <RecordStatus {...BOM.NewRecordStatusdata()} /> */}
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
