import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import RecordStatus from "../../ui-setup/RecordStatus";
import {BOM} from "../../../services/defined.action";
import appLabel from "../../../config/appLabel"
import { HandleSkeletonTbleFac, ColBlockFac,BackBtnFac} from "../../layouts/Facility";


export const DetailRole = (props) => {
  const { location, userData, recordData, menuData } = props;
  const { state } = location;
  const {profileId, session} = userData;
  const dispatch = useDispatch();
  const [roleDetail, setRoleDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    getDetailById(state.ggParam);
  }, []);

  function getDetailById(params) {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      roleId: params.roleId,
      sessionId:session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetRoleDetail, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        setIsLoading(false);
      } else {
        // console.log(res);
        BOM.LoadTimeout()

        let roleDetail = res.data;
        setRoleDetail(roleDetail);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: roleDetail,
        });
        //handle right side
        BOM.IsApproveOrReject();
      }
    });
  }
  
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.roleDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-content content-card-v2">
                  <ColBlockFac>
                      <BackBtnFac />
                  </ColBlockFac>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle text-navy" />
                      &nbsp; {appLabel.roleInformation}
                    </label>
                    <div className="hr-line-solid-v2" style={{ marginTop: 0 }} />
                    <div className="col-md-6">
                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">{appLabel.roleId}</label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={roleDetail.roleId || roleDetail.ROLE_ID}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">{appLabel.roleName}</label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={roleDetail.roleName || roleDetail.ROLE_NAME}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                          {appLabel.roleDescription}
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control "
                            defaultValue={roleDetail.roleDescription || roleDetail.ROLE_DESCRIPTION}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <RecordStatus {...recordData} /> */}
      {/* <RecordStatus {...BOM.NewRecordStatusdata()} /> */}
      </div>
    </Main>
  );
};


const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  // recordData: state.staticData.recordStatus
  recordData: state.recordStatus
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DetailRole);
