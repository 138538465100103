/**
 * @author Gedeon NdundeCode
 * @date 06/12/2021
 */
import Auth, { headerOptions as options, apis as api } from "../../api.action";
import { cnt } from "../../constant.action";
import history from "../../../history";
import { BOM } from "../../defined.action";
import { store } from "../store";
import { Buffer } from "buffer";
import appConfig from "../../../config/appConfig";

/** AUTH LOGIC */
export const SignInAction = (body) => {
  BOM.LoadAlert(cnt.LOAD, "Processing");

  return async (dispatch) => {
    BOM.SessionLessReqAction(body, api.Authentication, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        getRoleMenu(res.roleDataResponseModel); //GET DYNAMIC MENU FOR CURRENT USER

        dispatch({
          type: cnt.SIGN_IN,
          payload: res,
        });


        // decide which screen to go after login
        switch (res.userData.firstStatus.toLowerCase()) {
          case "a":
            history.push("/first-time-login-password");
            break;

          default:
            setTimeout(() => {
              history.push("/dashboard");
            }, 500);
            break;
        }
      }
    });
  };
};

export const VerifyAuthAction = (body) => {
  BOM.LoadAlert(cnt.LOAD, "Processing");

  return async (dispatch) => {
    BOM.SessionLessReqAction(body, api.Authentication, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        getRoleMenu(res.roleDataResponseModel); //GET DYNAMIC MENU FOR CURRENT USER

        dispatch({
          type: cnt.SIGN_IN,
          payload: res,
        });
        history.push("/order-to-verify");
      }
    });
  };
};

async function getRoleMenu(user) {
  // BOM.LoadAlert(cnt.LOAD, "Processing");

  let body = {
    profileId: user.profileId,
    roleId: user.userRoleId,
    session: user.session,
  };

  const actionTypes = {
    SET_ACCOUNT_NUMBER: 'SET_ACCOUNT_NUMBER',
    GENERATE_ACCOUNT_NUMBER: 'GENERATE_ACCOUNT_NUMBER'
  };

  BOM.FetchReqAction(body, api.GetRoleMenu, (err, res) => {
    if (err) {
      BOM.AlertMsg(cnt.DANGER, err);
    } else {

      const objList = res.roleDataResponseModel.functions
      store.dispatch({
        type: cnt.MENU_DATA,
        payload: objList,
      });
      // $("#side-menu").metisMenu();
    }
  });
}
export const SignOutAction = () => {
  return () => {
    // storage.removeItem('persistor:root')

    store.dispatch({ type: cnt.CLEAR_RIGHT_BTN });
    store.dispatch({ type: cnt.CLEAR_STATIC_DATA });
    store.dispatch({ type: cnt.SIGN_OUT });

    // history.push("/sign-in");
  };
};
