/**
 * @author openfactor
 * @date June 2023
 */
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { cnt } from "../../services/constant.action";

import {
  SignInAction,
  SignOutAction,
} from "../../services/redux/actions/auth-act";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { SubmitBtnLoad, OutlineFldErr, BgImgFac, HandleReqFldMsg } from "../layouts/Facility";
import Logo from "../../assets/img/logo/eco-logo-white-svg.svg";
import Bg from "../../assets/img/logo/banking-bg.jpg";

const LockScreen = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { history, SignInAction, SignOutAction, lockscreenData } = props;
  const { username, fullName } = lockscreenData;
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: cnt.LOCKSCREEN,
    });
  }, []);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
 
  const onSignIn = (formData) => {
    setIsLoading(true);
    formData.username = username;

    const body = {
        ...formData,
        sourceIp: "197.184.164.52", //ip.IPv4,
        browser: BOM.GetBrowserName(),
        host: window.location.host,
        osName: "windows",
        longitude: "-25.757", //ip.longitude + "",
        latitude: "28.1443", //ip.latitude + "",
        channel: "self service",
    };

   
    BOM.SessionLessReqAction(body, api.Authentication, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        setIsLoading(false);
      } else {
       
        BOM.NotifyAlert(cnt.SUCCESS, "Welcome back " + res.data.profileFirstName)
        dispatch({
          type: cnt.SIGN_IN,
          payload: res.data,
        });

        history.push("/dashboard");

      }
    });
  };
  
  

  return (
    <div style={{ overflowX: "hidden", overflowY: "auto" }}>
           <BgImgFac img={Bg} />
      <div className="centeredContent">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="middle-box loginscreen animated fadeInDown login-card"
                style={{ marginBottom: "0%" }}
              >
                <div className="text-center">
                  <img
                    alt="image"
                    style={{
                      width: "150px",
                      marginTop: "10px",
                      marginBottom: "25px",
                    }}
                    src={Logo}
                  />
                </div>
                <form
                  
                  onSubmit={handleSubmit(onSignIn)}
                  autoComplete="off"
                >
                  <div className="form-group">
                    <label className="text-left">
                      <p style={{ fontWeight: "normal", fontSize: "14px" }} className="text-white">
                        Hi, <strong>{fullName}</strong>. You are currently on
                        the lock screen; please enter your password to
                        proceed.
                      </p>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="text-left text-white">{appLabel.password}</label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className={`form-control input-sm $ ${OutlineFldErr(errors.userPassword)}`}
                      placeholder="********"
                      {...register("userPassword", {
                        required: HandleReqFldMsg(appLabel.password),
                        minLength: {
                          value: 8,
                          message: "Minimum password length is 8 characters",
                        },
                      })}
                    />
                    <span className="p-viewer" onClick={() => togglePassword()}>
                      <i
                        className={
                          passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </span>
                    <mark
                      className={`${
                        errors?.userPassword?.message ? "asterisks" : "hide"
                      }`}
                    >
                      {errors?.userPassword?.message}
                    </mark>
                  </div>
                  <div className="form-group text-center">
                    <SubmitBtnLoad
                      isLoading={isLoading}
                      btnName={appLabel.unlockBtn}
                      isFullWidth={true}
                      icon={'fa fa-unlock'}
                    />
                  </div>
                  <div className="form-group text-center">
                    <Link
                      to="/sign-in"
                      style={{ fontWeight: "bold", }}
                      className="text-white"
                    >
                      <i className="fa fa-chevron-circle-left" />{" "}
                      <small>{appLabel.goToLogin}</small>
                    </Link>
                  </div>
                </form>
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  lockscreenData: state.auth.lockscreenData,
});

const mapDispatchToProps = { SignInAction, SignOutAction };

export default connect(mapStateToProps, mapDispatchToProps)(LockScreen);
