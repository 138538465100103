/**
 * @author openfactor
 * @date June 2023
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";

import { SignOutAction } from "../../services/redux/actions/auth-act";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Logo from "../../assets/img/logo/eco-logo-white-svg.svg";
import axios from "axios";
import { SubmitBtnLoad, HandleReqFldMsg, BgImgFac } from "../layouts/Facility";
import Bg from "../../assets/img/logo/banking-bg.jpg";

const ForgotPassword = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { history } = props;

  useEffect(() => {
    SignOutAction();
  }, []);

  const onForgot = (formValues) => {
    const body = {
      ...formValues,
    };

    setIsLoading(true);
    BOM.SessionLessReqAction(body, api.ForgotPassword, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
        setIsLoading(false);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        history.push("/sign-in");
      }
    });
  };

  return (
    <div style={{ overflowX: "hidden", overflowY: "auto" }}>
      <BgImgFac img={Bg} />
      <div className="centeredContent">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="middle-box loginscreen animated fadeInDown login-card"
                style={{ marginTop: "10%", marginBottom: "0%" }}
              >
                <div className="text-center">
                  <img
                    alt="image"
                    style={{
                      width: "150px",
                      marginTop: "10px",
                      marginBottom: "25px",
                    }}
                    src={Logo}
                  />
                </div>
                <p className="text-white">
                  Please enter the address linked with your account.
                </p>
                <form onSubmit={handleSubmit(onForgot)} autoComplete="off">
                  <div className="form-group">
                    <label className="text-left text-white">
                      {appLabel.email}{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control input-sm"
                      placeholder={appLabel.email}
                      {...register("username", {
                        required: HandleReqFldMsg(appLabel.email),
                        pattern: {
                          value: BOM.RegExValidation("email"),
                          message: "Email must be valid.",
                        },
                      })}
                    />
                    <mark
                      className={`${
                        errors?.username?.message ? "asterisks" : "hide"
                      }`}
                    >
                      {errors?.username?.message}
                    </mark>
                  </div>
                  <div className="form-group text-center">
                    <SubmitBtnLoad
                      isLoading={isLoading}
                      btnName={appLabel.proceedBtn}
                      isFullWidth={true}
                      icon={"fa fa-arrow-circle-right"}
                    />
                  </div>
                  <div className="form-group text-center">
                    <Link
                      to="/sign-in"
                      style={{ fontWeight: "bold", color: "#fff" }}
                    >
                      <i className="fa fa-chevron-circle-left" />{" "}
                      <small>{appLabel.goToLogin}</small>
                    </Link>
                  </div>
                </form>
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { SignOutAction })(ForgotPassword);
