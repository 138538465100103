import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import RightSide from "../../ui-setup/RightSide";
import {
  Tooltips,
  ColBlockFac,
  NoDataFac,
  CreateInputFac,
  NavTabFac,
  HandleSkeletonDetailFac,
  TextAreaFac,
  AmountFacility,
  AmntFormatFac,
  AppModalFac,
  TableSimpleFac,
  ErrorHandlerFacIn,
  BackBtnFac,
} from "../../layouts/Facility";

function NewUser(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { location, userData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const [countryData, setCountryData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [subRoleData, setSubRoleData] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const { CRUD } = appStatic;
  useEffect(() => {
    getRole();
  }, []);

  function getRole() {
    let body = {
      profileId,
      criteria: "A",
      roleId: "",
      sessionId: session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetRole, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
      } else {
        const objList = res.data;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            name: element.roleDescription,
            value: element.roleId,
          });
        });

        const params = {
          roleData: temp,
        };

        setInputFields(CRUD.CreateUser(params));
      }
    });
  }

  const formSubmit = (formValues) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    formValues.criteria = "basic".toUpperCase();
    formValues.userTypeId = "UT0000000000001";

    let body = {
      profileId,
      sessionId: session,
      ...formValues,
    };

    BOM.FetchReqAction(body, api.CreateUser, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message || err.error);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        history.push("/manage-user");
      }
    });
  };

  return (
    <Main {...props} onSaveChange={handleSubmit(formSubmit)}>
      <form method="POST" id="NewItemForm" autoComplete="off">
        <ContentTop pageTitle={appLabel.newUser} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-content content-card-v2">
                  <ColBlockFac>
                    <BackBtnFac />
                  </ColBlockFac>
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="text-center">
                        <img
                          src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                          className="avatar img-circle img-thumbnail"
                          alt="avatar"
                        />
                      </div>
                      <br />
                    </div>

                    <div className="col-sm-10">
                      <ColBlockFac topTitle={appLabel.userSetup}>
                        {inputFields.map((item, index) => (
                          <div key={index}>
                            {item.section === "general" &&
                              item.fldType === "text" && (
                                <CreateInputFac
                                  inputLabel={item.name}
                                  fieldType={item.fldType}
                                  requiredLabel={item.required}
                                  dynamicClass={item.colClass}
                                >
                                  <input
                                    {...item.ggHtmlAttributes}
                                    {...register(item.value, {
                                      required: item.required
                                        ? BOM.CheckReqFld(item.name)
                                        : false,
                                      ...item.ggValidation,
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.[item.value]?.message}
                                  </span>
                                </CreateInputFac>
                              )}
                            {item.section === "general" &&
                              item.fldType === "select" && (
                                <CreateInputFac
                                  inputLabel={item.name}
                                  fieldType={item.fldType}
                                  requiredLabel={item.required}
                                  dynamicClass={item.colClass}
                                >
                                  <select
                                    {...item.ggHtmlAttributes}
                                    {...register(item.value, {
                                      required: item.required
                                        ? BOM.CheckReqFld(item.name)
                                        : false,
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {item.arrayData.map((subItem, subIndex) => (
                                      <option
                                        value={subItem.id || subItem.value}
                                        key={subIndex}
                                      >
                                        {subItem.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.[item.value]?.message}
                                  </span>
                                </CreateInputFac>
                              )}
                          </div>
                        ))}
                        <NoDataFac arrList={inputFields} />
                      </ColBlockFac>
                    </div>
                    {/*/tab-content*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <RecordStatus {...BOM.NewRecordStatusdata()} /> */}
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
