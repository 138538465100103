/**
 * @author Gedeon Gibango
 * @date June 2023
 */

import Notiflix from "notiflix";
import { cnt } from "./constant.action";
import history from "../history";
import { store } from "./redux/store";
import appLabel from "../config/appLabel";
import axios from "axios";
import appConfig from "../config/appConfig";

/** BM (Block Of Methods) */
export const BOM = {
  FetchReqAction: async (body, apiUrl, callback) => {
    const { headers } = appConfig.apiSettings;
    const method = "POST";
    body.channel = "self service";

    var config = {
      method,
      url: apiUrl,
      headers,
      data: body,
    };

    axios(config)
      .then(function (response) {
        let res = response.data;
        callback(null, res);
      })
      .catch(function (error) {
        // console.log(error);
        if (error.response) {
          const { data } = error?.response;
          
          switch (data?.message?.toLowerCase()) {
            case "invalid session":
              BOM.AlertMsg(cnt.DANGER, data.message);
              history.push("/");
              break;
            default:
              callback(data, null);
              break;
          }
        }
        BOM.AlertMsg(cnt.DANGER, appLabel.smthWentWrong);
      });
  },
  SessionLessReqAction: async (body, apiUrl, callback) => {
    const { headers } = appConfig.apiSettings;

    // console.log(headers);
    var config = {
      method: "post",
      url: apiUrl,
      headers,
      data: body,
    };

    axios(config)
      .then(function (response) {
        let res = response.data;
        callback(null, res);
      })
      .catch(function (error) {
        console.log(error.response);
        if (error) {
          const { data } = error.response;
          callback(data, null);
        }
        BOM.AlertMsg(cnt.DANGER, appLabel.smthWentWrong);
      });
  },
  AlertMsg: (type, message) => {
    Notiflix.Loading.remove();
    try {
      switch (type) {
        case cnt.WARNING:
          return Notiflix.Report.warning("WARNING", message, "OK");
        case cnt.SUCCESS:
          return Notiflix.Report.success("SUCCESS", message, "OK");
        case cnt.DANGER:
          return Notiflix.Report.failure("ERROR", message, "OK");
        case cnt.INFO:
          return Notiflix.Report.info("INFO", message, "OK");
        case cnt.COPIED:
          return Notiflix.Report.success("COPIED", message, "OK");
        default:
          return Notiflix.Report.warning("WARNING", message, "OK");
      }
    } catch (error) {
      console.log("error wow: ", error);
    }
  },
  NotifyAlert: (type, message) => {
    Notiflix.Loading.remove();
    try {
      switch (type) {
        case cnt.WARNING:
          return Notiflix.Notify.warning(message);
        case cnt.SUCCESS:
          return Notiflix.Notify.success(message);
        case cnt.DANGER:
          return Notiflix.Notify.failure(message);
        case cnt.INFO:
          return Notiflix.Notify.info(message);
        case cnt.COPIED:
          return Notiflix.Notify.success(message);
        default:
          return Notiflix.Notify.warning(message);
      }
    } catch (error) {
      console.log("error wow: ", error);
    }
  },
  LoadAlert: (type, message) => {
    try {
      switch (type.toLowerCase()) {
        case cnt.PROCESS:
          return Notiflix.Loading.dots(message + "...");
        case cnt.LOAD:
          return Notiflix.Loading.dots(message + "...");
        default:
          return Notiflix.Loading.dots(message + "...");
      }
    } catch (error) {
      console.log("error on loadingAlert func: ", error);
    }
  },
  GetBrowserName: () => {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) !== -1
    ) {
      return "Opera";
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return "Firefox";
    } else if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      !document.documentMode == true
    ) {
      return "Internet Explorer";
    } else {
      return "Not sure!";
    }
  },
  DatatableUsage: (title = "Record") => {
    $(document).ready(function () {
      setTimeout(function () {
        $("#myTable").DataTable({
          retrieve: true,
          // destroy: true,
          dom: '<"html5buttons"B>lTfgitp',
          buttons: [
            // {
            //   extend: "copy",
            // },
            {
              extend: "csv",
            },
            {
              extend: "excel",
              title: title + "_list",
            },
            {
              extend: "pdf",
              title: title + "_list",
            },

            {
              extend: "print",
              customize: function (win) {
                $(win.document.body).addClass("white-bg");
                $(win.document.body).css("font-size", "10px");

                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
            },
          ],
        });
      }, 700);
    });
  },
  DatatableUsage2: (title = "Record") => {
    $(document).ready(function () {
      setTimeout(function () {
        $(".myTable").DataTable({
          retrieve: true,
          // destroy: true,
          dom: '<"html5buttons"B>lTfgitp',
          buttons: [
            // {
            //   extend: "copy",
            // },
            {
              extend: "csv",
            },
            {
              extend: "excel",
              title: title + "_list",
            },
            {
              extend: "pdf",
              title: title + "_list",
            },

            {
              extend: "print",
              customize: function (win) {
                $(win.document.body).addClass("white-bg");
                $(win.document.body).css("font-size", "10px");

                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
            },
          ],
        });
      }, 700);
    });
  },
  GenerateTimestamp: () => {
    // get uniq time for a document sent;
    var now = new Date();
    var timestamp = now.getFullYear().toString();
    timestamp += (now.getMonth() + 1).toString();
    timestamp += now.getDate().toString() + "";
    timestamp += now.getHours().toString();
    timestamp += now.getMinutes().toString();
    timestamp += now.getSeconds().toString();
    timestamp += now.getMilliseconds().toString();
    return timestamp;
  },
  GetUnique: (arr, comp) => {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.lastIndexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  },
  RmvAccentOnly: (str_param) => {
    // str_param.replace(/\s+/g, " ");
    let mystr = str_param.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return mystr; //.replace(/'/g, "A");
  },
  GetWindowData: (key, option = "") => {
    switch (key.toLowerCase()) {
      case "origin":
        return window.location.origin + option;
      case "host":
        return window.location.host;
      case "hostname":
        return window.location.hostname;
      case "href":
        return window.location.href;
      case "pathname":
        return window.location.pathname;
      case "port":
        return window.location.port;
      case "protocol":
        return window.location.protocol;
      default:
        return window.location.origin;
    }
  },
  Uwfirst: (str) => {
    return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
      return letter.toUpperCase();
    });
  },
  IsApproveOrReject: (status = "exit", menuData = [], description = null) => {
    /** CHECK FOR APPROV/REJECT BTN PRIVILEGES */
    switch (status.toLowerCase().trim()) {
      case "axxx":
        if (RAL.rejectAction(menuData, description)) {
          [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_REJECT_BTN].map((item) => {
            store.dispatch({
              type: item,
            });
          });
        }

        break;

      case "rxxx":
        if (RAL.approvAction(menuData, description)) {
          [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_APPROVE_BTN].map((item) => {
            store.dispatch({
              type: item,
            });
          });
        }

        break;
      case "u":
      case "m":
      case "c":
        if (RAL.approvAction(menuData, description)) {
          [
            cnt.CLEAR_RIGHT_BTN,
            cnt.RIGHT_REJECT_BTN,
            cnt.RIGHT_APPROVE_BTN,
          ].map((item) => {
            store.dispatch({
              type: item,
            });
          });
        }

        break;
      default:
        [cnt.CLEAR_RIGHT_BTN].map((item) => {
          store.dispatch({
            type: item,
          });
        });
        break;
    }
  },

  GlobalApproveRejectAction: (body, reqUrl, redirectUrl, label, action) => {

    Notiflix.Confirm.show(
      "Are you sure?",
      action + label,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.LoadAlert(cnt.LOAD, "Processing");
        BOM.FetchReqAction(body, reqUrl, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err.message || err.error);
          } else {
            BOM.AlertMsg(cnt.SUCCESS, res.message);
            history.push(redirectUrl);
          }
        });
      },
      function () {
        // No button
      }
    );
  },
  ApproveRejectWithReload: (body, reqUrl, label, action) => {
   
    Notiflix.Confirm.show(
      action + label,
      "Are you sure?",
      "Yes",
      "No",
      function () {
        // Yes
        BOM.LoadAlert(cnt.LOAD, "Processing");
        BOM.SendReqActWithReload(body, reqUrl);
      },
      function () {
        // No button
      }
    );
  },
  LoadTimeout: () => {
    Notiflix.Loading.remove();
  },
  SystemTimeout: (param) => {
    return setTimeout(() => {
      BOM.LoadTimeout();
      console.log(`timeout occured on: ${param.toUpperCase()} request API`);
      Notiflix.Report.warning(
        "WARNING",
        "The server encountered something unexpected that didn't allow it to complete the request. We apologize.",
        "OK"
      );
    }, 60000);
  },
  CurrentDate: () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  NewRecordStatusdata: () => {
    return {
      FLG_STATUS: "U",
      MAKER_ID: "",
      MAKER_DATE: BOM.CurrentDate(),
      CHECKER_ID: "Not Applicable",
      CHECKER_DATE: "Not Applicable",
    };
  },
  handleFileRead: async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    const base64 = await BOM.convertBase64(file);
    let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
    setState_param(strB64);
    console.log(strB64);
  },
  convertBase64: (file) => {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  },
  DownloadAction: (base64File, fileName) => {
    fileName = BOM.ReplaceSpace(fileName, "_");
    let fileType = BOM.DetectMimeType(base64File);

    const source = "data:" + fileType + ";base64," + base64File;

    const downloadLink = document.createElement("a");

    downloadLink.href = source;
    downloadLink.download = fileName.replace(/ /g, ""); // rename your file
    downloadLink.click();
  },
  DetectMimeType: (b64) => {
    let signatures = {
      JVBERi0: "application/pdf",
      iVBORw0KGgo: "image/png",
      "/9j/4AAQSkZJRgABAQ": "image/jpeg",
    };
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  },
  ReplaceSpace: (str, character) => {
    return str.replace(/ /g, character);
  },
  ReformatDate: (dateParam) => {
    dateParam = new Date(dateParam);
    let dd = dateParam.getDate();
    let mm = dateParam.getMonth() + 1;
    let yyyy = dateParam.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  addDaysInDate: (dateParam, param = 0) => {
    const aDay = 60 * 60 * 24 * 1000; // seconds * minutes * hours * milliseconds = 1 day
    dateParam = new Date(dateParam.getTime() + aDay * param);
    let dd = dateParam.getDate();
    let mm = dateParam.getMonth() + 1;
    let yyyy = dateParam.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  DateTime: (myDate) => {
    // var myDate = new Date();

    let daysList = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let monthsList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Aug",
      "Oct",
      "Nov",
      "Dec",
    ];

    let date = myDate.getDate();
    let month = monthsList[myDate.getMonth()];
    let year = myDate.getFullYear();
    let day = daysList[myDate.getDay()];

    let today = `${date} ${month} ${year}`;

    let amOrPm;
    let twelveHours = function () {
      if (myDate.getHours() > 12) {
        amOrPm = "PM";
        let twentyFourHourTime = myDate.getHours();
        let conversion = twentyFourHourTime - 12;
        return `${conversion}`;
      } else {
        amOrPm = "AM";
        return `${myDate.getHours()}`;
      }
    };
    let hours = twelveHours();
    let minutes = myDate.getMinutes();
    let second = myDate.getSeconds().toString();

    let currentTime = `${hours}:${minutes}:${second} ${amOrPm.toLowerCase()}`;

    // console.log(today + " " + currentTime);

    return currentTime + " - " + today;
  },
  HandleCreateRight: (pathData, menuData, key) => {
    let path = pathData.find((item) => item.URL === key);
    // console.log(path);

    if (path) {
      if (RAL.createAction(menuData, path.DESCRIPTION)) {
        store.dispatch({
          type: cnt.RIGHT_ADD_BTN,
        });
      }
    }
  },
  SortArrayString: (arrayParam) => {
    /** ORDER ITM S BY POSITION */
    const myData = []
      .concat(arrayParam)
      .sort((a, b) => (a.POSITION > b.POSITION ? 1 : -1));

    return myData;
  },
  ReloadComponent: () => {
    window.location.reload(false);
  },
  ValidateNaNValue: (valueParam, labelParam) => {
    valueParam = parseFloat(valueParam);
    let isNaNVal = false;

    if (Number.isNaN(valueParam)) {
      BOM.AlertMsg(cnt.WARNING, labelParam + " is not a valid number.");
      isNaNVal = true;
    }

    if (isNaNVal) return 1;
    else return 0;
  },
  RegExValidation: (key) => {
    switch (key.toLowerCase()) {
      case "email":
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      case "password":
        return /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!?@#$%^&*/{};:,<>`])[a-zA-Z0-9!?@#$%^&*/{};:,<>`]{6,16}$/;
      default:
        return null;
    }
  },
  CheckReqFld: (key) => {
    return key + appLabel.isRequired;
  },

  MaskingString: (str, start, end) => {
    if (
      !str ||
      start < 0 ||
      start >= str.length ||
      end < 0 ||
      end > str.length ||
      start >= end
    ) {
      return str;
    }
    const maskLength = end - start;
    const maskedStr =
      str.substring(0, start) + "*".repeat(maskLength) + str.substring(end);
    return maskedStr;
  },
  OnError: (errors) => {
    BOM.AlertMsg(cnt.DANGER, appLabel.fldWithAsteRq);
    // console.log(errors);
  },
};

/** RPB (Right Privilege Button) */
export const RPB = (props) => {
  /** CALLING GLOBAL VARIABLE */
  const globalVar = window.globalData;

  store.dispatch({
    type: cnt.CLEAR_RIGHT_BTN,
  });

  switch (props.location.pathname.toLowerCase()) {
    case "/dashboard":
      break;
    /** THE FOLLOWINGS WILL NOT HAVE CREATE BTN */
    case "/manage-role":
      setTimeout(() => {
        store.dispatch({
          type: cnt.CLEAR_RIGHT_BTN,
        });
      }, 1500);

      break;
    case "/role-detail":
    case "/account-detail":
    case "/deregister-account-detail":
      [cnt.RIGHT_APPROVE_BTN, cnt.RIGHT_REJECT_BTN].map((item) => {
        store.dispatch({
          type: item,
        });
      });
      break;
    case "/manage-user":
      break;
    case "/new-user":
    case "/edit-user":
    case "/change-password":
      store.dispatch({
        type: cnt.RIGHT_SAVE_BTN,
      });
      break;
  }
};

/** RAL (Right Action Logic) */
export const RAL = {
  GetStoreMenu: async () => {
    let menu = [];
    setTimeout(() => {
      menu = store.getState().staticData.menuData;
    }, 500);

    return menu;
  },
  searchAction: function (menuData, submenuDescription) {
    return this.checkSearchAction(menuData, submenuDescription);
  },
  checkSearchAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.search === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.search === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action create :', found);
      return true;
    } else {
      console.log("Action create :", found);
      return false;
    }
  },
  createAction: function (menuData, submenuDescription) {
    return this.checkCreateAction(menuData, submenuDescription);
  },
  checkCreateAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.create === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.create === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action create :', found);
      return true;
    } else {
      console.log("Action create :", found);
      return false;
    }
  },
  approvAction: function (menuData, submenuDescription) {
    return this.checkApprovAction(menuData, submenuDescription);
  },
  checkApprovAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.approve === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.approve === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action approve :', found);
      return true;
    } else {
      console.log("Action approve :", found);
      return false;
    }
  },
  rejectAction: function (menuData, submenuDescription) {
    return this.checkRejectAction(menuData, submenuDescription);
  },
  checkRejectAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.reject === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.reject === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action reject :', found);
      return true;
    } else {
      console.log("Action reject :", found);
      return false;
    }
  },
  editAction: function (menuData, submenuDescription) {
    return this.checkEditAction(menuData, submenuDescription);
  },
  checkEditAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.edit === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.edit === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action edit :', found);
      return true;
    } else {
      // console.log('Action edit :', found);
      return false;
    }
  },
  checkViewAction: function (myArr, keySub) {
    // console.log(myArr);
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.view === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.view === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      return true;
    } else {
      console.log("Action view :", found);
      return false;
    }
  },
  viewAction: function (menuData, submenuDescription) {
    return this.checkViewAction(menuData, submenuDescription);
  },
  checkDeleteAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.delete === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.delete === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      return true;
    } else {
      console.log("Action delete :", found);
      return false;
    }
  },
  deleteAction: function (menuData, submenuDescription) {
    return this.checkDeleteAction(menuData, submenuDescription);
  },
  disableEnableRightAction: function (arrParam = ["CLEAR_RIGHT_BTN"]) {
    // let pathArray = arrParam ? arrParam : ["CLEAR_RIGHT_BTN"];

    arrParam.map((item) => {
      store.dispatch({
        type: item,
      });
    });
  },
};

/** initialise notiflix pop up */
export const notifInitialization = () => {
  Notiflix.Confirm.init({
    className: "notiflix-confirm",
    width: "300px",
    zindex: 4003,
    position: "center",
    distance: "10px",
    backgroundColor: "#f8f8f8",
    borderRadius: "25px",
    backOverlay: true,
    backOverlayColor: "rgba(0,0,0,0.5)",
    rtl: false,
    fontFamily: "Quicksand",
    cssAnimation: true,
    cssAnimationDuration: 300,
    cssAnimationStyle: "fade",
    plainText: true,
    titleColor: "#005B82",
    titleFontSize: "16px",
    titleMaxLength: 34,
    messageColor: "#1e1e1e",
    messageFontSize: "14px",
    messageMaxLength: 200,
    buttonsFontSize: "15px",
    buttonsMaxLength: 34,
    okButtonColor: "#f8f8f8",
    okButtonBackground: "#005B82",
    cancelButtonColor: "#f8f8f8",
    cancelButtonBackground: "#a9a9a9",
  });
};
